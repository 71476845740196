.itemPageheader {
  margin-top: 50px;
}

.itemPageDetails {
  border-bottom: 1px solid;
  padding-left: 10px;
  padding-bottom: 5px;
  font-size: 20px;
  margin-bottom: 10px;
}

.itemPageSideRightContainer {
  padding-left: 20px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  font-size: 13px;
  margin-top: 10px;
}
.emptySpace20 {
  padding-top: 20px;
}

.itemPageAmountName {
  float: left;
  color: #b6b5b5;
  white-space: nowrap;
  padding-right: 20px;
}
.itemPageAmountContainer {
  border-bottom: 1px solid lightgray;
  width: 100%;
  display: flex;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.itemtaskAlertBox {
  padding-left: 10px;
  font-size: 13px;
}
.itemPageAmountContainer2 {
  width: 100%;
  display: flex;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dateType2 {
  font-size: 14px;
  padding-bottom: 5px;
  color: gray;
  padding-top: 10px;
}
.itemPageAmountNumber {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  float: right;
  text-align: right;
  margin-right: 20px;
  text-overflow: ellipsis;
}
.itemPageAmountNumber2 {
  font-size: 10px;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  float: right;
  text-align: right;
  margin-right: 20px;
  text-overflow: ellipsis;
}
.itemPageSideLeftContainer {
  margin-left: 50%;
}
.itemPageImgContainer {
  float: left;
  width: 100px;
  background: blue;
  margin-right: 20px;
}
.itemPageImg {
  width: 100%;
}
.itemPageRightContainer {
  float: left;
}
.itemPageName {
  font-size: 2em;
  padding-bottom: 15px;
  padding-left: 10px;
}
.itemPageSerialContainer {
  font-size: 13px;
  color: gray;
}

.itemAreaContainer {
  font-size: 13px;
  color: gray;
}

/* .itemPageSideRightContainer {
  float: left;
  padding-left: 15px;
}

.itemPageSideLeftContainer {
  margin-left: 50%;
}
.itemPageImgContainer {
  float: left;
  width: 100px;
  background: blue;
  margin-right: 20px;
}
.itemPageImg {
  width: 100%;
}
.itemPageRightContainer {
  float: left;
}
.itemPageName {
  font-size: 2em;
  padding-bottom: 15px;
  padding-left: 10px;
}
.itemPageSerialContainer {
  font-size: 13px;
  color: gray;
}

.itemAreaContainer {
  font-size: 13px;
  color: gray;
  margin-top: 40px;
  padding-left: 15px;
}
*/

.itemPageSerial {
  margin-top: 1px;
}

.itemPageChecklist {
  width: 100%;
  padding-bottom: 130px;
}
.itemPageChecklistHeader {
  padding-left: 10px;

  font-size: 20px;

  padding-bottom: 4px;
}

.itemPageInspectionContainer {
  padding-bottom: 10px;
}

.itemPageTask {
  width: 100%;
  padding-bottom: 50px;
  margin-top: 20px;
}

.scheduledEventContainer {
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 15px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  padding-left: 20px;
}

.scheduledEventContainerDisabled {
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 15px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  padding-left: 20px;
  color: #b6b6b6;
}

.box2 {
  position: relative;
  margin: 0 auto;
  max-height: 70vh;
  margin-top: 50px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;

  margin-left: 10px;
  margin-right: 10px;
}

.nice-dates {
  padding-bottom: 10px;
}

.dateType {
  font-size: 10px;
  padding-bottom: 5px;
  color: gray;
}

.itemPageApparatusListContainer {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  padding-top: 10px;
  display: flex;
}

/* Slider Info */
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-top: 0px;
  padding-top: 20px;
  overflow-y: hidden;
  padding-bottom: 10px;
}
.silderHolderItemPage {
  padding-top: 10px;
}

.scheduledEventEdit {
  float: right;
  right: 50px;
  position: absolute;
  display: block;
  padding-top: 10px;
}

.scheduledEventRepeat {
  padding-top: 5px;
}

.primary3 {
  justify-content: center;
  display: flex;
  max-height: 80vh;
}

.itemPageChecklist2 {
  width: 100%;
}
.itemPageHistorySelect {
  padding-bottom: 5px;
  font-size: 16px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.PDFclose {
  color: #000;
  font-size: 25px;
  font-weight: bold;
  top: -20px;
  display: flex;
  position: absolute;
  z-index: 101;
  border-radius: 50%;
  border: 4px solid #000;
  padding-left: 5px;
  padding-right: 5px;
  left: -20px;
}

.downloadPdfBtn {
  right: 30px;
  position: absolute;
  background: #e5e3e3;
  padding: 5px;
  border-radius: 10px;
  border: 1px black solid;
  z-index: 1;
  width: 100px;
}
#PdfEmbed {
  height: 100%;
}
.ItemPageHolderEmpty {
  padding-bottom: 60px;
}

.HolderEmpty {
  margin-bottom: 50px;
}
.PdfPopupDiv {
  background-color: #fefefe;
  margin: 6% auto;
  border: 3px solid #000;
  width: 80%;
  position: absolute;
  z-index: 100;
  top: 0;
  height: 90%;
  margin-left: -100px;
  margin-top: 60px;
}
.PdfPopupDivHolder {
  width: 100%;
  justify-content: center;
  display: flex;
}

.ItemHistoryHolder {
  display: FLEX;
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 10px;
  padding-top: 10px;
}

.ItemHistoryContainer {
  width: 100%;
}
.ItemHistoryLeft {
  font-size: 14px;
  float: left;
}
.ItemHistoryNote {
  font-size: 12px;
}

.ItemHistoryDetails {
  margin-top: 10px;
}
.ItemHistoryRight {
  float: right;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
  font-size: 10px;
}

@media (max-width: 500px) {
  .itemPageHistorySelect {
    width: 70px;
  }
}
.primary222 {
  justify-content: center;
  display: flex;
}

.slick-active {
  height: auto;
}

.primaryContainer222 {
  position: relative;
  width: 97%;
}

.itemPageBottom {
  margin-bottom: 100px;
}

.ItemHistoryPhotos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap between images as needed */
}

.ItemHistoryPhoto {
  flex: 1 1 calc(20% - 10px); /* Adjust the width of each image */
  max-width: calc(
    20% - 10px
  ); /* Ensure the max-width is the same as the flex basis */
  box-sizing: border-box;
}

.ItemHistoryPhoto img {
  width: 100%;
  height: auto;
  display: block;
}

.itemPageSideRightContainer {
  /* Your existing styles */
}

.itemPageAmountContainer {
  display: flex;
  align-items: center;
}

.itemPageAmountName {
  width: 150px;
}

.itemPageAmountNumber {
  flex: 1;
}

.itemPageAmountNumber2 {
  flex: 1;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
}

.itemTag {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 10px;
  color: #333;
  border: 1px solid #ccc;
}

.removeTagButton {
  background: none;
  border: none;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
  color: #555;
}

.removeTagButton:hover {
  color: #000;
}

.uniquePopupContainer {
  /* Add any needed styles for the popup container */
}

.uniqueMiddleContainer {
  /* Add any needed styles for the middle container */
}

.uniqueMiddle {
  /* Add any needed styles for the middle */
}

.uniqueItemStatusHeaderContainer {
  /* Add any needed styles for the item status header container */
}

.uniqueItemStatusList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.uniqueItemStatusListItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.uniqueRadioLabelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.uniqueRadioLabelWrapper label {
  margin-right: auto;
  font-size: 16px; /* Adjust font size */
}

.uniqueRadioLabelWrapper input[type="radio"] {
  margin-left: auto;
  width: 20px; /* Adjust width */
  height: 20px; /* Adjust height */
  cursor: pointer;
}

.primary4 {
  margin-left: 10px;
}

.unassign-button {
  background-color: #dc3545; /* Red color for unassign */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  display: block;
  margin: 20px auto 0 auto; /* Center the Unassign button below the form */
}

.unassign-button:hover {
  opacity: 0.8;
}

.popup-unassign-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
