.trainingContainer {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.trainingMonth {
  font-size: 15px;
  color: #848787;
  padding-top: 15px;
  padding-left: 10px;
}
.trainingDay {
  padding-top: 2px;
  padding-bottom: 3px;
  color: #848787;
  float: right;
}
.trainingListContainer {
  margin-top: 10px;
  padding: 10px;
  border-radius: 15px;
  border: 1.5px solid #b2b2b2;
}

.inventorySelect22 {
  width: 50%;
}
.selectOption {
  width: 100%;
  margin-left: 10px;
  margin-bottom: 20px;
}
.trainingCalendar {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 15px;
  z-index: 5;
}
.previousTrainigPageHeader {
  margin-top: 45px;
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  float: left;
  margin-bottom: 25px;
}

.trainingDivHolder {
  margin-bottom: 150px;
}

.training {
  padding-left: 20px;
}
.trainingName {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 58%;
}
.trainingDetails {
  padding-left: 20px;
}
.trainingDesc {
  font-size: 13px;
  padding-left: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}
.trainingTrainer {
  width: 100%;
  display: flex;
  font-size: 13px;
  padding-left: 5px;  
    margin-top: 4px;
}
.trainingInProgressContainer {
  border-bottom: 1px solid black;
}


.trainingCompletedCountHolder {
  display: flex;
  font-size: 13px;
  margin-top: 10px;
}


.trainingCompletedCountName {
  display: inline-block;
  width: 33%;
  text-align: center;
}
