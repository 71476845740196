.memberListHolder {
  display: flex;
  padding-top: 13px;
  padding-bottom: 10px;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.memberListHolder:hover {
  background-color: lightgray;

  -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.memberListHolderPic {
  float: left;
  padding-left: 10px;
  margin-right: 10px;
}

.memberListDivTable {
  display: table;
  width: auto;
  border-spacing: 5px; /*cellspacing:poor IE support for  this*/
  /* border-collapse:separate;*/
  width: 100%;
  margin-left: 40px;
}

.memberListheaders {
}

.memberListheadersContent {
  float: left; /*fix for  buggy browsers*/
  display: table-column;
  width: 26%;
  overflow-wrap: break-word;
}

.memberListheadersContentSmall {
  float: left; /*fix for  buggy browsers*/
  display: table-column;
  width: 10%;
  overflow-wrap: break-word;
}
.topbarEmptySpace22 {
  margin-bottom: 10px;
}
.memberListRow {
  display: table-row;
  width: auto;
}

.memberListHolderCell {
  float: left; /*fix for  buggy browsers*/
  display: table-column;
  width: 26%;
  overflow-wrap: break-word;
}
.memberListHolderCellSmall {
  float: left; /*fix for  buggy browsers*/
  display: table-column;
  width: 10%;
  overflow-wrap: break-word;
}

.searchDiv1 {
  margin: auto;
  width: 95%;
}

.searchDiv11 {
  margin: auto;
  padding-top: 20px;
}

.memberListHolderName {
  float: left;
  margin-right: 20%;
  margin-top: 2px;
  white-space: nowrap;
}

.memberListHolderRole {
  font-size: 12px;
}

.memberListHolderArrow {
  font-size: 25px;
  margin-right: 10px;
  padding-top: 7px;
  position: absolute;
  right: 10px;
}

.memberListHolderMobile {
  padding-top: 13px;
  padding-bottom: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  background: #393939;
  margin-bottom: 20px;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
}
.memberListheadersContentMobile {
  color: #797979;
}

.memberListHolderCellMobile {
  margin-bottom: 10px;
}

.memberListHolderCellRightMobile {
  padding-top: 2px;
  padding-bottom: 3px;
  color: #848787;
  float: right;
  margin-right: 20px;
}

.memberListHolderMobile {
  background: white;
  border: 1px black solid;
}
