body.no-scroll {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.appContainer.no-scroll {
    overflow-y: hidden;
  }
.modal-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Ensure it covers the full viewport width */
    height: 100vh; /* Ensure it covers the full viewport height */
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 101;    align-items: flex-start; /* Align items to the start (top) */

}

.modal {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;


}

.modal-desktop {
    max-width: 500px;
}

.modal-mobile {
    width: 100%;
    height: 100%;
    border-radius: 0;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
    padding: 10px 0;
}

.modal-header h2 {
    margin: 0;
}

.modal-body-wrapper {
    overflow-y: auto;
    flex: 1;
}

.modal-body {
    padding: 10px 0;
}
.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 10;
    padding: 10px 0;
}

.modal-footer button,
.donePopup,
.unassign-button,
.closePopup {
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.modal-footer button:hover,
.donePopup:hover,
.closePopup:hover,
.submit-button:hover,
.unassign-button ,
.cancel-button:hover {
    background: #0056b3;
}

.close-button {
    position: absolute;
    top: 35px;
    right: 25px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 20; /* Ensure it appears above other elements */
    font-size: 32px;
}

.submit-button {
    margin-left: auto;
    background: #28a745;
}

.cancel-button {
    background: #dc3545; /* Different color for cancel button */
}


/* Media query for Dekstop view */
@media (min-width: 1500px) {
    .modal {
        max-height: 80vh; /* Set a maximum height */
        max-width: 500px; 
         margin-top: 100px;
    }

}


/* Media query for mobile view */
@media (max-width: 1500px) {
    .modal-header {
        position: absolute;
        padding-top: 25px;
        width: 100% !important;
        top: 0px;
    }
    
    .modal-body {
        margin-top: 60px; /* Adjust based on your header height */
    }
    .modal-footer {
        padding-bottom: 70px;
        padding-top: 30px;
    }


}

.errorInputLabel {
    color: red;
    padding-top: 5PX;
  }