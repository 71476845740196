.userInfoHeaderName {
  font-size: 30px;
  margin-bottom: 10px;
  padding-left: 5px;
  float: left;
  margin-right: 20px;
}

.detailsPopupContainer22 {
  padding-bottom: 20px;
  max-height: 400px;
  padding-top: 10px;
  z-index: 2;
  position: relative;
}
.downloadPdfBtnTrianing {
  right: 30px;
  position: absolute;
  background: #e5e3e3;
  padding: 5px;
  border-radius: 10px;
  border: 1px black solid;
  z-index: 1;
  width: 100px;
  top: 55px;
}

.downloadPdfBtnTrianingNote {
  right: 0px;
  position: absolute;
  z-index: 1;
  width: 150px;
  top: 43px;
  font-size: 10px;
}
.notelistMembers {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.editITrainingBtnContainer {
  background: #ea5252;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 50%;
  border-radius: 5px;
  padding: 3px 0px;
  color: white;
  margin-bottom: 100px;
}

.userInfoEventTypeName {
  color: gray;
  font-size: 13px;
  padding-left: 10px;
}

.eventHolder {
  padding-bottom: 300px;
}
.userInfoContainer {
  overflow-x: hidden;
}

.itemPageSideRightContainer2 {
  padding-left: 20px;
  border-bottom: 1px solid lightgray;
  font-size: 13px;
}

.profileHeaderHolderName {
  padding-top: 10px;
}

.profileHeaderContainer {
  overflow-x: hidden;
  margin-top: 20px;
}

.profileHeaderHolder {
  padding-top: 30px;
  justify-content: center;
  display: grid;
  width: 100%;
  padding-bottom: 40px;
  border-bottom: 1px solid;
}
.profileHeaderHolderPic {
  justify-content: center;
  display: flex;
}

.profileBoxContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  justify-content: center;
  margin-top: 20px;
}

.profileBoxHolder {
  padding: 5px;
  max-width: 100px;
  border: 1px solid;
  margin-bottom: 10px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 3px;
}
.smallOnScreen {
  z-index: 3;
  position: sticky;
  top: 0;
  align-items: center;
  display: flex;
  font-size: 20px;
  border-bottom: 1.5px rgb(109, 109, 113) solid;
  width: 100%;
  justify-content: center;
}

.eventsHolder {
  margin-top: 60px;
  padding-left: 10px;
  padding-bottom: 5px;
}

.individualTrainingInfoTopHolder {
  border-bottom: 1px solid #000;
  margin-top: 10px;
  padding-bottom: 20px;
}

.individualTrainingInfoDateHolder {
  color: #639eb6;
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 5px;
  float: left;
  height: 10px;
}

.individualTrainingInfoEventHolder {
  color: #639eb6;
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 5px;
  float: right;
  padding-right: 10px;
}

.individualTrainingInfoDescHolder {
  font-size: 14px;
  width: 90%;
  color: #141414;
  margin-left: 5px;
  padding-bottom: 6px;
  margin-top: 9px;
  padding-left: 10px;
}
.individualTrainingInfoTrainerHolder {
  display: flex;
}

.individualTrainingInfoTrainerName {
  padding-top: 10px;
  margin-left: 5px;
}
