.ProfileWidgetBox {
  margin-top: 10px;
  border: 1.5px solid #d5d5d5;
  border-radius: 10px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 40px;
  max-height: 300px;
  overflow: auto;
}

.ProfileWidgetContainer {
  margin-bottom: 130px;
}
.widgetTitleContainer {
  padding-left: 5px;
  margin-top: 25px;
}
.ProfileWidgetInformation {
  width: 100%;
}
.ProfileTrainingContainer {
  margin-top: 15px;
}

.ProfileTrainingListContainer {
  margin-top: 10px;
  padding: 10px;
  border-radius: 15px;
  border: 1.5px solid #b2b2b2;
}
.ProfileTrainingName {
  float: left;
}
.ProfileTrainingDesc {
  width: 100%;
  display: inline-block;
}

.ProfileTrainingDay {
  padding-top: 2px;
  padding-bottom: 3px;
  color: #848787;
  float: right;
}
.ProfileTrainingMonth {
  font-size: 15px;
  color: #848787;
  padding-top: 15px;
  padding-left: 10px;
}

.profileHeaderName {
  margin-top: 25px;
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.ProfileWidgetEmpty {
  text-align: center;
  top: 20px;
  position: relative;
}
