.popupboxScanner {
  position: fixed;
  background: #0000008c;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 7;
}

.scannerBox {
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 70%;
}

.qrScanner {
  width: 100%;
}

.qrscannerContainerResults {
  height: 400px;
}

.cameraFlip {
  font-size: 25px;
  position: absolute;
  right: 16px;
  color: black;
  cursor: pointer;
  padding-top: 5px;
}

section {
  border-radius: 20px;
}

.scannerInputHolder {
  display: flex;
}

.submitScannerBtn {
  font-size: 15px;
  border: none;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  padding: 5px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  margin-bottom: 10px;
  outline: none;
  background: none;
  display: flex;
  position: absolute;
  right: 25px;
}

.addItemInput2 {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: block;
  background: lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  text-indent: 5px;
  margin-right: 80px;
}

.coverUp {
  margin-right: 1px;
  margin-top: 2px;
  padding-left: 17px;
  position: fixed;
  z-index: 100;
  right: 5%;
}

.qrscannerContainer {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  padding-bottom: 30px;
  width: 100%;
}

.scannerInputContainer {
  padding-top: 20px;
  padding-bottom: 10px;
}

.scannerInputContainer2 {
  display: flex;
  flex-direction: row;
}

/*






*/
#qr-canvas {
  margin: auto;
  width: calc(100% - 20px);
  max-width: 400px;
}

#btn-scan-qr {
  cursor: pointer;
}

#btn-scan-qr img {
  height: 10em;
  padding: 15px;
  margin: 15px;
  background: white;
}

#qr-result {
  font-size: 1.2em;
  margin: 20px auto;
  padding: 20px;
  max-width: 700px;
  background-color: white;
}
.restartPageDiv {
  text-align: center;
  margin-top: 20%;
}
.restartPageBtn {
  border: none;
  color: #171616;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 10px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  background: #fcfcff;
}
