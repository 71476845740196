.hometopbarleft {
  display: inline-block;
  position: absolute;

  top: 5px;
  margin-right: 10px;
  font-size: 25px;
  z-index: 4;
  padding: 5px 10px 10px 8px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.widgetApparatusImg {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  float: left;
}
.ProfileContainers {
  display: inline-block;

  width: 100%;
  margin-top: 20px;
}
.ProfilePicture {
  padding-left: 10px;
  float: left;
}
.ProfileName {
  margin-left: 10px;
  padding-top: 10px;
  float: left;
}
.ProfileArrow {
  position: absolute;
  margin-right: 10px;
  font-size: 25px;
  right: 10px;
  padding-top: 10px;
}

.dashboardDiv {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 120px;
}
.dashboardDivTitle {
  display: flex;
}

.dashboardDivAdd {
  position: absolute;
  right: 5px;
  background: #acebff;
  border-radius: 5px;
  padding: 3px;
}

.widgetBox {
  margin-top: 10px;
  border: 1.5px solid #d5d5d5;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 30px;
}
.widgetRemove {
  float: right;
  margin-right: 10px;
}
.widgetTitle {
  float: left;
  padding-left: 5px;
  margin-top: 4px;
}
.widgetInformation {
  width: 100%;
  margin-top: 35px;
}

/* Apparatus widgets */
.widgetApparatusHolder {
  margin-top: 30px;
  border: 2.5px solid #d5d5d5;
  border-radius: 10px;
  padding: 10px;
}
.widgetApparatusHolderImage {
  display: flex;
  float: left;
}

.widgetApparatusName {
  margin-left: 5px;
  padding-bottom: 5px;
  display: flex;
}

.widgetApparatusNameAlert {
  margin-left: 5px;
  margin-bottom: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.widgetApparatusINStatus {
  font-size: 8px;
  background: #19d319;
  color: white;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  position: absolute;
  right: 30px;
}
.widgetApparatusOUTStatus {
  font-size: 8px;
  background: #f33737d4;
  color: white;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  position: absolute;
  right: 30px;
}

.widgetApparatusbox {
  padding-bottom: 10px;
  margin-left: 90px;
}

.widgetApparatusChecklistIcon {
  display: flex;
  margin-top: 5px;
  color: #6b6b6b;
}

.widgetApparatusCheckAmount {
  text-align: center;
  font-size: 20px;
  padding-left: 10px;
}
.widgetApparatusCheckName {
  width: 100%;
  text-align: center;
}

.widgetApparatusAlertIcon {
  display: flex;
  margin-top: 15px;
  color: red;
}

.widgetApparatusInventoryCheckAmount {
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.widgetApparatusInventoryCheckName {
  width: 100%;
  text-align: center;
}
.widgetApparatusAlertsAmount {
  text-align: center;
  font-size: 20px;
  padding-left: 10px;
}
.widgetApparatusAlertsName {
  width: 100%;
  text-align: center;
}

/* Alert box widgets */
.widgetApparatusAlertBox {
  border-top: 1px solid;
}
.widgetApparatusAlertBoxInformation {
  display: flex;
  font-size: 12px;
  padding-top: 5px;
}
.widgetApparatusHolder33 {
  border-bottom: 1px solid;
}
.widgetApparatusAlertBoxDate {
  color: red;
}
.widgetApparatusAlertBoxMember {
  margin-left: 10px;
}
.widgetApparatusToDoBoxDate {
  color: #a99474;
}
.widgetApparatusAlertBoxDetails {
  font-size: 13px;
  padding-bottom: 5px;
  padding-top: 3px;
  margin-left: 10px;
}

.widgetApparatusAlertFooter {
  display: flex;
  font-size: 13px;
  padding-top: 5px;
  padding-top: 10px;
}

.widgetApparatusAlertAdd {
  padding-left: 20px;
  z-index: 2;
  display: inline-block;
  cursor: pointer;
  position: sticky;
  color: #767676;
}
.widgetApparatusAlertDelete {
  padding-left: 30px;
  z-index: 2;
}
.HomePopup__Body--open {
  overflow-y: hidden;
}

.updatePasswordContainer {
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 99;
}

.alertEmptyContainer {
  width: 100%;
  justify-content: center;
  display: flex;
}

.alertEmptyContainer2 {
  border: 1.5px solid #d5d5d5;
  background: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
}

.loginTitle1 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 35px;
}

.loginContainer2 {
  position: fixed;
  top: 20%;
  left: 50%;
  margin-top: -50px;
  margin-left: -140px;
  width: 59%;
}

.loginTitle3 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
}

.widgetPopupHeader {
  padding-top: 10px;
}

.closePopup23 {
  top: 39px;
  cursor: pointer;
  position: fixed;
  font-size: 25px;

  z-index: 4;
}

.widgetDetailsHeaderDate {
  color: #ff2e2e;
  display: inline-block;
}

.widgetDetailsHeaderDateToDo {
  color: #a99474;
  display: inline-block;
}
.widgetApparatusToDoBoxDate2 {
  color: #a99474;
  display: inline-block;
}
.topbarEmptySpace123 {
  padding-bottom: 30px;
}

.noPicDivHolderImage {
  text-align: center;
  font-size: 50px;
  width: 60px;
  float: left;
}

.todo-comments {
  color: #555;
  font-style: italic;
}

.comment-icon {
  margin-right: 5px;
  color: #888;
}

@media screen and (max-width: 1000px) {
  .downloadPdfBtnHolder {
    display: none;
  }
}
