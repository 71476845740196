.loginContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
}

.loginInput {
  margin-bottom: 10px;
  justify-content: center;
  align-content: center;
  display: grid;
}
.loginUsername {
  font-size: 10px;
  margin-top: 10px;
}

.loginTitle {
  display: flex;
  margin: auto 0;
  width: 100%;
  justify-content: center;
}
.loginInputHolder {
  background: white;
  border-radius: 10px;
  border-top-color: blue;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}
.loginInputHolderTopColor {
  box-shadow: inset 0 4px 0 0 #ff2400;
  border-radius: 10px;
  padding: 30px;
}

.forgotInputHolder {
  margin-top: 20px;
}

.logonLogoIMG {
  width: 130px;
}

.loginForgotPassword {
  padding: 5px;
  margin-bottom: 20px;
  color: #a1a1a4;
  float: left;
}

.loginForgotPasswordLink {
  text-decoration: none;
  font-size: 14px;
}

.createAccountHolder {
  padding: 5px;
  margin-bottom: 20px;
  color: #a1a1a4;
  right: 0px;
  position: absolute;
}

.newAccountLink {
  text-decoration: none;
  font-size: 14px;
}
.login__field {
  padding: 20px 0px;
  position: relative;
}

.login__icon {
  position: absolute;
  top: 30px;
  color: #7875b5;
}
.login__input:active,
.login__input:focus,
.login__input:hover {
  border-right: none;
  border-top: none;
  border-left: none;
  border-bottom: 2px solid #0b71af;
}

.login__input {
  border: none;
  border-bottom: 1px solid #2fb1ff;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  font-size: 16px;
  transition: 0.2s;
  border-top: none;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.loginForgotPassword {
  padding: 5px;
  margin-bottom: 20px;
  color: #a1a1a4;
  float: left;
}

.loginBtnContainer {
  display: flex;
  margin: auto 0;
  justify-content: center;
}

.loginBtn {
  background-color: #ff4a4a;
  color: #fff;
  padding: 11px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  border: none;
}
.loginInputTitle {
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
  font-size: 20px;
  color: #5b5b5b;
}
.loginForgotInputHolder {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
.alert {
  background: rgb(241, 215, 217);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  color: rgb(105, 64, 63);
  border: 0.01px solid;
  margin-top: 30px;
}

.success {
  background: rgb(166, 238, 191);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  color: rgb(54, 77, 45);
  border: 0.01px solid;
}

.loginHeader {
  font-size: 30px;
  position: fixed;
  top: 30px;
  width: 100%;
  text-align: center;
  color: #d93636;
}

.loginTitle .logonLogoIMG {
  pointer-events: none;
}
