.pageHeader {
  margin-top: 45px;
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  float: left;
}
.popupboxTrainingScanner {
  position: fixed;
  background: #0000008c;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 2;
}

.submitItemBtn53 {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  left: 85px;
  color: rgba(56, 129, 141);
  font-weight: normal;
  z-index: 11;
  transition: all 0.5s ease;
}

.overFlow-content {
  overflow: visible;
}
.holderTestEmpty2 {
  padding-bottom: 80px;
}
.addEdit {
  display: inline-block;
  position: absolute;
  right: 3px;
  top: 15px;
  margin-right: 10px;

  z-index: 5;
}

.trainingHeaderName {
  margin-top: 70px;
  font-size: 20px;
  margin-bottom: 10px;
  padding-left: 5px;
}
.topbarTraining {
  height: 45px;
  display: flex;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
}

.trainingPageSideRightContainer {
  padding-left: 10px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  font-size: 13px;
  margin-top: 10px;
}

.reviewTraining {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 10px;
  margin-right: 10px;
  font-size: 25px;
}

.itemPageAmountNumber22 {
  width: 100%;
  float: right;
  text-align: right;
  margin-right: 20px;
  text-overflow: ellipsis;
}

/* .training {
  padding-left: 20px;
}
.trainingDetails {
  padding-left: 20px;
}
.trainingTrainer {
  padding-left: 20px;
}
.trainingInProgressContainer {
  border-bottom: 1px solid black;
} */

.filter-search-toggle-container-popup {
  display: flex;
  flex-direction: column;
}
.addScan22 {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.personalContainer {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 100px;
}
.personalContainer2 {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.profileBoxContainer2 {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  justify-content: center;
  margin-top: 50px;
}
.profileBoxName1 {
  padding-bottom: 6px;
}

.select__multi-value {
  display: flex !important;
  min-width: 0px !important;
  background-color: rgb(243, 244, 246) !important;
  border-radius: 1rem !important;
  margin: 2px !important;
  box-sizing: border-box !important;
  border: 1px solid rgb(84 85 87) !important;
}

.profileBoxHolder2 {
  padding: 20px;
  width: 80px;
  border: 1px solid;
  margin-bottom: 10px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 3px;
  height: 40px;
  font-size: 15px;
}
.personal {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
  padding-top: 15px;
}

.btnDiv {
  padding-bottom: 15px;
}
.popupBtnContainers {
  text-align: center;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.holderTestEmpty {
  padding-bottom: 28px;
}

.popupTitle45 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  padding-top: 10px;
  margin: auto;
  width: 45%;
  font-size: 20px;
}

.personalContainerTraining {
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.editTrainingDetails {
  font-size: 20px;
  border: none;
  background: lightblue;
  cursor: pointer;
  padding-top: 5px;
  border-radius: 5px;
  width: 100%;
}
.addMemberBtn {
  font-size: 17px;
  background: #efefef;
  cursor: pointer;
  padding-top: 5px;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding-bottom: 5px;
}
.closetrainingBtn {
  font-size: 20px;
  border: none;
  background: lightblue;
  cursor: pointer;
  padding-top: 5px;
  border-radius: 5px;
  width: 100%;
}

.signInBtnDiv {
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 90px;
  margin-right: 10px;
  font-size: 25px;
}
.signInBtn {
  font-size: 20px;
  border: none;
  background: #e22323;
  cursor: pointer;
  padding: 7px;
  border-radius: 10px;
}

.memberListName {
  float: left;
}
.memberList {
  margin-left: 30px;
  margin-right: 30px;
}
.member {
  float: left;
  padding-left: 2px;
}

.trainingMembersList {
  margin-top: 10px;
  padding-left: 10px;

  padding-bottom: 5px;
}

.holderTest2 {
  padding-bottom: 10px;
  z-index: 10;
  position: fixed;
  width: 100%;
}
.detailsPopupContainer3 {
  padding-bottom: 20px;
  max-height: 400px;
  padding-top: 10px;
}
.detailsPopupContainer23 {
  padding-bottom: 20px;
  max-height: 400px;
  padding-top: 40px;
}
.emptySpace22 {
  margin-top: 20px;
}

.detailsPopupContainer223 {
  max-height: 400px;
  padding-bottom: 100px;
}

.trainingItemContainer2 {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding-left: 20px;
  padding-right: 20px;
}

.holderTestNew {
  border-radius: 3px;
  padding-bottom: 10px;
  position: fixed;
  width: 100%;
  z-index: 10;
  background: rgb(242, 241, 245);
}

.botttomEmpty {
  padding-bottom: 200px;
}

.EmptyTrainingDiv {
  justify-content: center;
  display: flex;
  margin-top: 10px;
}

.detailsTitle {
  font-size: 25px;
  border-bottom: 1px solid;
  padding-left: 10px;
  padding-bottom: 10px;
}
.holderTestEmptyNew {
  height: 110px;
}
.trainingListPageHolder2 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.trainingListItemHolder2 {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
}

.searchDiv33 {
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.custom-select3 {
  font-size: 16px;
  padding-left: 20px;
  padding-right: 26px;
}

.trainingInProgressPhotosHolder {
  display: inline-block;
}
.confirmTrainingBtn {
  background: #79d956;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 50%;
  border-radius: 5px;
  padding: 3px 0px;
  color: #424242;
  margin-bottom: 5px;
}

.editTrainingBtnContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.addItemTraining {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 3px;
  margin-right: 10px;
  font-size: 29px;
  z-index: 2;
}

.trainingItemName2 {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding-bottom: 2px;
}

.trainingItemAmount2 {
  position: absolute;
  right: 10px;
  margin-right: 20px;
  padding-top: 13px;
}

.trainingInfoDetails {
  margin-left: 30px;
  margin-right: 30px;
}

.trainingPicturesDiv {
  display: flex;
  width: 100%;
  font-size: 25px;
  padding-top: 20px;
  padding-left: 10px;
  border-bottom: 1px black solid;
}

.trainingHeader {
  margin-top: 45px;
  font-size: 20px;
  padding-left: 10px;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.picturesDiv {
  margin-left: 30px;
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 100px;
}

.trainingImg {
  width: 100px;
  height: 100px;
}

.upcomingEventContainer {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.upcomingEventDate {
  float: left;
  padding-right: 20px;
  white-space: nowrap;
  top: 10px;
  position: relative;
  font-size: 15px;
}

.upcomingEventName {
  float: right;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  max-width: 60%;
}

.upcomingEventName2 {
  display: inline-block;
  font-size: 12px;
  justify-content: right;
  width: 100%;
  text-align: right;
}

.calendarTraining {
  display: inline-block;
  position: absolute;
  right: 60px;
  top: 15px;
  z-index: 5;
}

.DayPicker-Day--event {
  background-color: #1391f2;
  color: white;
}

.trainingBox {
  top: 45px;
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 70%;
  overflow: auto;
}

.trainingBox33 {
  top: 45px;
  position: relative;
  margin: 0 auto;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 70%;
}
.newTrainingBtn {
  position: absolute;
  right: 10px;
  font-size: 17px;
  cursor: pointer;
  top: 8px;
  padding: 5px;
}

.trainingInProgressPhotos {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.trainingInProgressImg {
  width: 70px;
  margin-right: 10px;
  height: auto;
  float: left;
  max-width: 235px;
}

/** Add TRAINING ITEMS CSS**/

.addMember {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 5px;
  margin-right: 10px;
  font-size: 30px;
  z-index: 2;
}
.trainingItemApparatusName {
  border-bottom: 1px solid lightgray;
  font-size: 15px;
  color: #919191;
  padding-bottom: 5px;
  padding-top: 25px;
}

.trainingItemCompartmentName {
  border-bottom: 1px solid lightgray;
  color: #919191;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-left: 10px;
}

.trainingItemContainer {
  margin: auto;

  padding-bottom: 30px;
  margin-left: 20px;
  margin-right: 5px;
  padding-top: 10px;
  border-bottom: 1px solid lightgray;
}

.trainingItemName {
  float: left;
  padding-left: 10px;
}

.trainingItemAmount {
  float: right;
  padding-right: 40px;
}

.trainingItemBagRow {
  margin: auto;
  margin-bottom: 15px;
  padding-top: 10px;
  margin-left: 20px;
  margin-right: 5px;
}
.trainingItemBagRow2 {
  margin: auto;
  margin-bottom: 15px;
  padding-top: 10px;
  margin-left: 20px;
  margin-right: 5px;
  border-bottom: 1px solid lightgray;
}

.trainingItemBagName {
  float: left;
  padding-left: 10px;

  margin-right: 5px;
}

.trainingItemBagDropdown {
  padding-top: 30px;

  border-bottom: 1px solid lightgray;
}

.trainingItemBagContainer {
  padding-top: 10px;
  border-top: 1px solid lightgray;
  margin-top: 5px;
}

.trainingItemBagItemName {
  float: left;
  font-size: 16px;
  margin-bottom: 10px;
  padding-left: 30px;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.trainingItemBagItemAmount {
  text-align: right;
  padding-right: 26px;
  height: 25px;
  font-size: 16px;
  padding-top: 2px;
}

.popup-box2 {
  position: fixed;
  background: #0000008c;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.topbar2 {
  height: 45px;
  display: flex;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 3;
  background: white;
  top: 0;
}
.holderEmpty21 {
  padding-bottom: 100px;
}

.trainingBoxScanner {
  top: 60px;
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 70%;
}

.addScan2 {
  display: inline-block;
  position: absolute;
  right: 50px;
  top: 8px;
  margin-right: 10px;
  font-size: 25px;
  z-index: 2;
}

.select__control.css-1s2u09g-control {
  width: 98%;
}
.addMemberTraining {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 5px;
  margin-right: 10px;
  font-size: 25px;
  z-index: 2;
}
.removeMemberNameContainer {
  width: 100%;
  height: 10px;
  display: flex;
  padding-bottom: 25px;
  font-size: 25px;
  justify-content: center;
}

.removeMemberBtnContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding-top: 20px;
}

.removeMemberBtn {
  border: none;
  color: #171616;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 10px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 25px;
  padding-left: 25px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.removePhotoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.removeInProgressImg {
  width: 100%;
  margin-right: 10px;
  height: auto;
  float: left;
  max-width: 235px;
}

.removePhotoBtnContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.trainingItemBagItemsContainer {
  width: 100%;
  margin: auto;
  display: none;
}

.trainingBagDropdownArrow {
  position: absolute;
  right: 15px;
  width: 30px;
  text-align: right;
  padding: 10px;
}

.trainingBagDropdownArrow2 {
  width: 60px;
  text-align: right;
  padding-right: 15px;
}
.trainingBagNameHolder {
  display: flex;
}
.trainingBagNameHolder2 {
  display: flex;
  padding-bottom: 10px;
}
.apparatusBagPopupitemTextHolder1 {
  color: lightgray;
  margin-top: 10px;
  border-radius: 5px;
  padding-bottom: 20px;
  border-bottom: 1px solid;
}

.appartusBagPopupItemsLeft {
  margin-top: 50px;
  justify-content: center;
  display: flex;
  font-size: 20px;
}
.trainerDiv {
  padding-bottom: 5px;
  width: 99%;
}
.appartusBagPopupItemsAdded {
  margin-top: 30px;
  justify-content: center;
  display: flex;
  font-size: 20px;
  margin-bottom: 20px;
}

.closePopupMember {
  position: absolute;
  top: 6px;
  font-size: 29px;
  cursor: pointer;
  padding-top: 5px;
  z-index: 1;
}
.trainingBox2 {
  top: 45px;
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 15px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
}

.newTrainingBtn22 {
  position: absolute;
  right: 10px;
  font-size: 17px;
  cursor: pointer;

  padding: 3px;
}

.newTest {
  background: white;
  padding-bottom: 10px;
  position: absolute;
  padding-bottom: 70px;
  display: flex;
  width: 100%;
  border-radius: 10px;
  padding-top: 5px;
}

.popupTitle2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 16px;
  width: 100%;
}

.select-container {
  padding-left: 10px;
  padding-right: 10px;
}

.nice-dates-popover {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  margin: 8px 0;
  max-width: 600px;
  position: absolute;
  transform-origin: top;
  transform: perspective(1000px);
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.1);
  width: 90% !important;
  will-change: transform, opacity;
  z-index: 9;
}

.trainingEmptyContainer {
  background: #fff;
  padding-top: 100px;
  position: absolute;
  width: 100%;
  justify-content: center;
  display: flex;
  padding-bottom: 100px;
}
.trainingEmptyContainer2 {
  border: 1.5px solid #d5d5d5;
  padding: 40px;
  border-radius: 10px;
  font-size: 15px;
}

@media (max-width: 500px) {
  .trainingEmptyContainer2 {
    padding: 20px;
    border-radius: 10px;
    font-size: 13px;
  }
}

.textinput {
  font-size: 16px;
  max-height: 350px;
  outline: none;
  padding-top: 30px;
  border-color: Transparent;
  overflow: auto;
  border-bottom: 1px solid gray;
  width: 100%;
  resize: none;
}
.holderEmpty {
  padding-bottom: 55px;
}

.NewInputArea {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding-top: 27px;
  padding-bottom: 100px;
  padding-left: 15px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #afafaf;
  appearance: none;
  background-color: white;
  font-size: 16px;
  color: black;
  max-height: 350px;
}

.BottomPaddingList {
  padding-bottom: 105px;
}

.NewInputArea:focus {
  outline: none;
  border: 2px solid rgba(56, 129, 141);
  font-weight: bold;
}
.text-center.p-3 {
  justify-content: center;
  display: flex;
  position: sticky;
  margin-bottom: 5px;
}
.css-q3o1l2 {
  margin-bottom: 10px;
}

.basic-multi-select {
  text-indent: 5px;
}

#react-select-2-input {
  padding-left: 5px !important;
}

.sliderHolder {
  padding-bottom: 140px;
}

.noteListHolder {
  border: 2.5px solid #d5d5d5;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.noteListEdit {
  right: 20px;
  position: absolute;

  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #5ba4f3;
  cursor: pointer;
}
.noteListNote {
  padding-top: 10px;
  margin-left: 10px;
  font-size: 14px;
}
.noteListSubject {
  padding-bottom: 10px;
  width: 90%;
}
.noteListSubjectHolder {
  display: flex;
  border-bottom: 1px solid;
}

.noteSubmitItemBtn {
  background: none;
  border: none;
  font-size: 16px;
  position: absolute;
  right: 20px;
  color: #5ba4f3;
  cursor: pointer;
}

.trainerSelectHolder {
  display: flex;
  margin-bottom: 10px;
  width: 99%;
}
.typeSelectDiv {
  width: 100%;
  padding-right: 10px;
}

.typeSelectDiv2 {
  width: 100%;
  max-width: 50%;
}
