.itemChecklistTaskHeaderContainer {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
  padding-left: 15px;
  background: lightblue;
  margin-top: 80px;
}

.bottomHolder {
  padding-bottom: 200px;
}

.itemRow2 {
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 30px;
  font-size: 16px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
}

.itemChecklistTaskHeaderContainer2 {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
  color: white;
  background: red;
  margin-top: 80px;
  margin-bottom: 10px;
}

.itemIdentificationInProgressContainer2 {
  float: left;
  padding-left: 10px;
}

.serialNumberInProgressContainer2 {
  display: inline-block;
  position: absolute;
  right: 10px;
  margin-right: 10px;
  padding-right: 20px;
}

.itemAmount2 {
  padding-right: 20px;
  float: right;
}
