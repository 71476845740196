.InspectionShowDiv {
  background: lightblue;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.InspectionDownDown {
  display: none;
}
.apparatusHeader {
  margin-top: 45px;
  font-size: 2em;
  padding-left: 10px;
  padding-bottom: 10px;
}

.compartmentNameEmpty {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-left: 10px;
}
.emptySpace2 {
  padding-top: 70px;
}
.apparatusMenu {
  overflow: hidden;
  text-align: center;
  float: left;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 5px;
  position: absolute;
  top: 80px;
}

.bagDropdownArrow {
  margin-top: 10px;
  margin-right: 10px;
}
.inventoryLink {
  border: none;
  color: #171616;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 10px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 5;
  padding-bottom: 5;
  padding-right: 15;
  padding-left: 15;
}

.apparatusInventoryEdit {
  border: none;
  color: #171616;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 10px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
  padding-left: 20px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.editApparatusCompartmentBtnContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  margin-top: 10px;
}
.apparatusCompartmentHolder {
  margin-top: 50px;
}
.apparatusCompartmentHolder2 {
  margin-bottom: 20px;
  padding-top: 50px;
}
.apparatusPhotoCompartmentHolder {
  padding-bottom: 20px;
  padding-left: 10px;
}
.compartmentImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  float: left;
}
.apparatusInventoryHolder {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  padding-top: 10px;
  display: flex;
}
.apparatusInventoryImgDiv {
  flex-basis: 60px;
}

.apparatusInventoryImg {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.addScan3 {
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
  position: absolute;
  right: 50px;
  top: 8px;
  z-index: 10;
}

.apparatusInventoryItemName {
  padding-top: 15px;
  padding-left: 5px;
}
.apparatusPhotoCompartmentText {
  padding-right: 10px;
  padding-left: 110px;
  font-size: 27px;
  padding-top: 30px;
}

.apparatusCompartmentText {
  padding-right: 10px;
  padding-left: 10px;
  font-size: 30px;
}

.itemTextHolder {
  color: lightgray;
  margin-bottom: 20px;
  border-radius: 5px;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  padding-top: 30px;
}

.itemText1 {
  float: left;
  padding-left: 20px;
}

.amountText1 {
  float: right;
  margin-right: 25px;
}

.list {
  overflow: auto;
  padding-bottom: 350px;
}

.overflowBox {
  top: 45px;
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 70%;
  overflow: auto;
}

.holderDiv {
  margin-bottom: 30px;
}
.holderTestEmptyNew1 {
  padding-bottom: 120px;
}
.popup-overlay.overFlowPopup-overlay {
  bottom: 80px !important;
}

.itemRow {
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 35px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
}
.itemRow2 {
  margin: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  width: 100%;
}

.itemName {
  float: left;
  padding-left: 10px;
}
.itemAmount {
  float: right;
  padding-right: 40px;
}
.popupTitleLocation {
  display: inline-block;
  position: absolute;
  top: 8px;
  margin-right: 10px;
  font-size: 20px;
  z-index: 10;
  left: 80px;
}
.bagRow {
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 5px;
  margin-right: 5px;
}

.bagName {
  padding-left: 10px;
  padding-bottom: 10px;
  width: 99%;

  padding-top: 10px;
}
.bagName2 {
  display: flex;
}
.bagQRDiv {
  text-align: center;
  padding-bottom: 5px;
}

.bagDropdown {
  padding-bottom: 10px;
  width: 90%;
  margin: auto;
  display: none;
}
.apparatusBagLocation {
  margin: auto;
  display: none;
}

.show {
  display: block !important;
}
.bagItem {
  float: left;
  font-size: 18px;
  padding-left: 10px;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bagAmount {
  text-align: right;
  padding-right: 16px;
  height: 25px;
  font-size: 18px;
}
.bagItemHolder {
  margin-bottom: 5px;
}
.arrowdown {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.bagDropdwonArrow {
  padding-right: 10px;
  width: 25px;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bagDropdwonArrow2 {
  padding-right: 10px;
  width: 25px;
  text-align: right;
  padding-bottom: 5px;
  float: right;
}
.selectInventoryHolder {
  background: rgba(242, 241, 245);
  border-radius: 3px;
  padding-bottom: 5px;
  position: sticky;
  width: 100%;
  z-index: 10;
  top: 0;
}
.addItemInput23 {
  width: 99%;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 100%;
  display: block;
  background: lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
  text-indent: 10px;
}
.popupContainer2 {
  padding-left: 20px;
  padding-right: 20px;
}
#start {
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 100%;
  display: block;
  background: lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  text-indent: 10px;
}
.essentialText {
  color: #d10202;
  font-size: 14px;
  margin-top: 10px;
}

.unassignDiv {
  background: red;
  padding-top: 15px;
  padding-left: 5px;
  color: white;
  margin-left: 10px;
}
.unassignDiv2 {
  background: red;
  padding-top: 10px;
  padding-left: 5px;
  color: white;
  border-radius: 10px;
  height: 35px;
  margin-left: 10px;
}

.compartmentSelectHolder {
  height: 25px;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 7px;
}
.compartmentSelectHolderBag {
  height: 25px;
  padding-top: 10px;

  padding-bottom: 7px;
}
.compartmentSelectText {
  font-size: 16px;
  float: left;
  padding-top: 4px;
  cursor: pointer;
}
.compartmentSelected2 {
  float: right;
  font-size: 16px;
  padding-top: 4px;
  color: #bababa;
}
.compartmentSelected {
  float: right;
  font-size: 16px;
  padding-top: 4px;
  background: transparent;
  border: none;
  width: 50%;
  text-align: right;
  pointer-events: none;
  color: #7d7d7d;
  overflow: hidden;
  white-space: nowrap;
}

.bagSelectHolder {
  height: 25px;
  padding-top: 5px;

  padding-bottom: 7px;
}

.bagSelectText {
  font-size: 16px;
  float: left;
  padding-top: 4px;
}

.itemSearchInput {
  margin-top: 10px;
  font-size: 15px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid black;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: none;
  background-color: lightgray;
}

.selectBagHolder {
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
  padding-top: 15px;
}
.inventoryItem {
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
  padding-top: 15px;
}

.optionalText {
  color: #06c506;
  margin-top: 20px;
}

.statusList {
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
  padding-top: 15px;
}

.appratusBagPopup {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}

.apparatusBagImg {
  height: 200px;
  border-radius: 10px;
}

.apparatusBagPopupitemTextHolder {
  color: lightgray;
  margin-top: 20px;

  padding-bottom: 20px;
  border-bottom: 1px solid;
  margin-bottom: 20px;
}

.apparatusBagPopupitemTextHolder2 {
  color: lightgray;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid;
}

.inventoryImgDivEmpty {
  margin-left: 70px;
}

.appartusBagPopupContainer {
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch;
}

.resultsDiv {
  margin-top: 40px;
}

.topbar22 {
  height: 45px;
  display: flex;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 3;
  background: rgba(242, 241, 245);
  top: 0;
}

.apparatusPopupBox {
  top: 15px;
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 80%;
  overflow: auto;
}
.addItem2 {
  display: inline-block;
  position: absolute;
  right: 10px;
  margin-right: 10px;
  font-size: 25px;
  z-index: 5;
}

.popupTitleCompartment {
  display: flex;
  justify-content: center;
}
.popupTitleName {
  font-size: 16px;
  margin-right: 40px;
  padding: 5px;
  border-radius: 5px;
}
.popupTitleName2 {
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
}

.holderTest {
  background: rgba(242, 241, 245);

  padding-bottom: 15px;
  z-index: 10;
  position: sticky;
  top: 0;
}

.holderTest55 {
  background: rgba(242, 241, 245);

  padding-bottom: 10px;
  z-index: 10;
  position: fixed;
  width: 100%;
  max-height: 110px;
  overflow: auto;
}

.itemName2 {
  padding-left: 10px;
}

.apparatusImgInventory2 {
  width: 100%;
  border-radius: 10px;
}
.itemPicture {
  width: 60px;
  font-size: 15px;
  margin-left: 5px;
}
.itemRow3 {
  border-radius: 5px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 13px -8px;
  display: flex;
  width: 95%;
  position: relative;
  margin: auto;
  padding-top: 5px;
  margin-bottom: 15px;
}

.searchDiv2 {
  margin: auto;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.holderTestEmpty1 {
  padding-bottom: 10px;
}

.apparatusImgInventoryContainer {
  margin-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.apparatusImgInventoryDiv {
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.apparatusImgInventory {
  width: 100%;
  border-radius: 10px;
  height: 450px;
}

.holderTestEmpty3 {
  height: 10px;
}
.custom-select2 {
  font-size: 16px;

  padding-bottom: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hiddenAddItem {
  display: block;
}

.itemContainer2 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid lightgray;
  margin-left: 20px;
  margin-right: 20px;
  max-height: 70px;
  padding-top: 10px;
  height: 100px;
}

.quickButtonContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  justify-content: center;
  margin-top: 20px;
}

.quickButtonHolder {
  padding: 20px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
}

.quickButtonIcon {
  font-size: 80px;
}

.quickButtonName {
  font-size: 15px;
}

@media screen and (min-width: 700px) {
  .apparatusBagImg {
    height: 350px;
  }
}

.apparatusInvEmpty {
  padding-bottom: 10px;
}

.apparatusInvEmpty2 {
  padding-bottom: 100px;
}

@media screen and (max-width: 1000px) {
  .quickButtonContainer {
  }
}

.selectBag {
  padding-left: 20px;
}

.itemStatusListLI {
  color: #aaaaaa;
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.itemStatusListLI input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.itemStatusListLI label {
  display: block;
  position: relative;
  font-size: 15px;
  padding: 10px 0px 0px 60px;
  margin: 10px auto;
  -webkit-transition: all 0.25s linear;
}

.itemStatusListLI:hover label {
  color: #ffffff;
}

.itemStatusListLI .check {
  display: block;
  position: absolute;
  border: 5px solid #aaaaaa;
  border-radius: 100%;
  height: 10px;
  width: 10px;
  top: 20px;
  left: 20px;
  z-index: 5;
  -webkit-transition: border 0.25s linear;
}

.itemStatusListLI:hover .check {
  border: 5px solid #ffffff;
}

.itemStatusListLI .check::before {
}

input[name="StatusSelector"]:checked ~ .check {
  border: 5px solid #4e9cc3;
}

input[name="StatusSelector"]:checked ~ .check::before {
  background: #4e9cc3;
}

input[name="StatusSelector"]:checked ~ label {
  color: #4e9cc3;
}
.itemStatusHeaderContainer {
  padding-left: 20px;

  font-size: 13px;
}

.bottomSubmitButtonContainer {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-bottom: 10px;
  font-size: 15px;
}

.bottomSubmitButton {
  background-color: #5f625f;
  font-size: 30px;
  padding: 10px 100px 10px 100px;
  border-radius: 10px;
}
