.itemAmountContainer {
  margin-top: 40px;
}

.totalItemAmount {
  font-size: 20px;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}
.itemPageContainer1 {
  border-top: 1px solid #cbcbcb;
  margin-top: 10px;
}
.itemListHeader {
  font-size: 15px;
  color: #919191;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 20px;
}
.itemListContainer {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  padding-bottom: 70px;

  margin: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 15px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
}

.itemPageContainer {
  margin-bottom: 10px;
}
.goBackDiv {
  display: inline-block;
  position: absolute;
  top: 10px;
  margin-right: 10px;
  font-size: 25px;
  z-index: 5;
  margin-left: 10px;
  cursor: pointer;
  color: #38818d;
}
.itemPageNameContainer {
  float: left;
}
.itemPageLocation {
  color: #6a6969;
  font-size: 14px;
  padding-left: 10px;
}

.itemPageAmount {
  float: right;
  padding-top: 10px;
}

.itemPageListImg {
  width: 100px;
  float: left;
  margin-right: 10px;
  max-height: 100px;
  border-radius: 5px;
}

.itemListHeaderContainer {
  margin-top: 60px;
  padding-left: 20px;
}

.searchDiv3 {
  padding-bottom: 20px;
  border-top: 1px solid #cbcbcb;
  margin-top: 10px;
}

.itemListDetailsContainer {
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.itemDetailsContainer {
  padding-top: 20px;
  font-size: 13px;
  color: #949494;
}

.itemManufacturer {
  float: left;
  max-width: 20%;
}
.itemManufacturer2 {
  padding-top: 3px;
}
.itemDescription {
  overflow: hidden;
  padding-left: 20px;
  max-width: 30%;
}
.itemDescription2 {
  padding-top: 3px;
  overflow: hidden;
}

.itemListSearchInput {
  font-size: 15px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid black;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: none;
  background-color: lightgray;
  margin-bottom: 10px;
}

.itemListEventHolder {
  padding-left: 10px;
  padding-right: 10px;
  height: 480px;
  padding-bottom: 20px;
  border-top: 1px solid #cbcbcb;
  margin-top: 10px;
}

.itemFirstEventName {
  background: #0cc3ff;
  width: 99%;
  height: 25px;
  padding-top: 5px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 5px;
}

.eventListContainer {
  padding-top: 30px;
}
.itemListEventName2 {
  font-size: 24px;
  position: absolute;
}

.addEventTask {
  display: inline-block;
  position: absolute;
  right: 10px;
  margin-right: 10px;
  font-size: 25px;
  z-index: 2;
}

.itemListEventName {
  float: left;
  padding-left: 8px;
}

.addItemTaskBtn {
  right: 10px;
  z-index: 2;
  position: absolute;
}

.additemListTaskDescBtn {
  right: 40px;
  z-index: 2;
  position: absolute;
}

.itemListTaskDesc {
  float: left;
  padding-left: 15px;
}

.itemListPageName {
  cursor: pointer;
  display: inline-block;
  padding-bottom: 2px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  text-overflow: ellipsis;
}

.itemListContainerHolder {
  display: flex;

  border-bottom: 1px solid lightgray;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 10px;
}
.itemListContainerHolderYellow {
  display: flex;
  border-bottom: 1px solid lightgray;
  margin-left: 20px;
  margin-right: 20px;
  background: #fff07ad1;
  padding-left: 10px;
  border-radius: 5px;
}
.itemListContainerHolderRed {
  display: flex;
  border-bottom: 1px solid lightgray;
  margin-left: 20px;
  margin-right: 20px;
  background: #ff88888c;
  padding-left: 10px;
  border-radius: 5px;
}

.itemListPageAmount {
  position: absolute;
  right: 10px;
  margin-right: 20px;
  padding-top: 13px;
}
.itemListPageAmount2 {
  position: absolute;
  right: 10px;
  margin-right: 20px;
  padding-top: 10px;
}

.itemListPageHolder {
  padding-top: 5px;
  padding-bottom: 5px;
}
.itemListPageHolder2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.itemPageListInputHolder {
  margin-left: 20px;
  margin-right: 20px;
}

.itemListTaskDescContainer {
  background: #fff;
  height: 25px;
  padding-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.userItemListPageHolder {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 55%;
}
.userItemListPageAmount {
  position: absolute;
  right: 10px;
  margin-right: 20px;
  padding-top: 8px;
}
/* Slider Info */
.silderHolder {
  margin-bottom: 30px;
}

.itemListPageEmpty {
  margin-bottom: 100px;
}

.itemPageHelp {
  display: flex;
  padding-left: 20px;
  padding-right: 26px;
  font-style: italic;
  padding-top: 10px;
}

.itemPageHelpYellowHolder {
  display: flex;
}

.itemPageHelpYellowColor {
  height: 20px;
  background: #fff07ad1;
  width: 20px;
  margin-right: 5px;
}

.itemPageHelpRedHolder {
  display: flex;
  padding-left: 20px;
}
.itemPageHelpRedColor {
  height: 20px;
  background: #ff88888c;
  width: 20px;
  margin-right: 5px;
}

.itemListContainerHolderOrange {
  display: flex;
  border-bottom: 1px solid lightgray;
  margin-left: 20px;
  margin-right: 20px;
  background: #e89a55ba;
  padding-left: 10px;
  border-radius: 5px;
}

.itemPageHelpOrangeColor {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background: #e89a55ba;
}

.itemListPageLastHydro {
  display: flex;
  padding-left: 20px;
  padding-right: 26px;
  font-style: italic;
  padding-top: 10px;
}

.react-tabs__tab {
  color: #afa9a9 !important;
  font-size: 17px !important;
}
.react-tabs__tab--selected {
  color: #2f2f2f !important;
  border: none !important;
}
.react-tabs__tab-list {
  justify-content: center;
  display: flex;
  margin: 0;
  border-bottom: none !important;
}

.detailedSelect {
  font-size: 16px;
  margin-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
}
