.todo-box {
  background-color: #f4f6f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
}

.todo-box h2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
}

.new-todo-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
  display: block;
  text-align: left;
}

.new-todo-btn:hover {
  background-color: #218838;
}

.todo-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.todo-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: box-shadow 0.3s ease;
}

.todo-card:hover {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}

.todo-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.todo-header .todo-title-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.todo-icon {
  margin-right: 10px;
  color: #007bff;
  font-size: 1.5em;
}

.todo-title {
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
  margin-right: 15px;
  flex: 1;
  text-align: left;
}

.todo-note {
  font-size: 1em;
  color: #888;
  font-style: italic;
  text-align: left;
  margin-left: 35px; /* Aligns it with the title considering the icon's size */
}

.todo-comments {
  padding-left: 20px;
  width: 100%; /* Ensure comments take full width */
}

.todo-comments li {
  font-size: 0.9em;
  color: #555;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  background-color: #f9f9f9;
  position: relative;
}

.todo-comments .comment-icon {
  margin-right: 5px;
  color: #6c757d;
}

.comment-text {
  display: block;
  margin-bottom: 5px;
}

.comment-meta {
  font-size: 0.8em;
  color: #888;
  margin-top: 5px;
}

.comment-date {
  font-weight: bold;
}

.comment-username {
  font-style: italic;
}

.todo-card-footer {
  margin-top: 10px;
  width: 100%;
  text-align: right; /* Align button to the right */
}

.todo-card-footer button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
  align-self: flex-end; /* Align button to the right */
}

.todo-card-footer button:hover {
  background-color: #0056b3;
}

.todo-box a {
  display: block;
  text-align: left;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.todo-box a:hover {
  text-decoration: underline;
}

/* Responsive Adjustments */



.popupContent {
  padding: 20px;
}

.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.popupTitleContainer {
  display: flex;
  align-items: center;
}

.popupTitle3 {
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 10px;
}

.editButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.editButton:hover {
  background-color: #0056b3;
}

.popupBody {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.widgetDetailsHeaderHolder {
  margin-bottom: 10px;
}

.widgetApparatusAlertBoxDetails {
  margin-top: 10px;
  color: #555;
}

.widgetDetailsCommentHolder {
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.commentItem {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.commentItem:hover {
  background-color: #e2e2e2;
}

.noComments {
  text-align: center;
  color: #777;
}

.popupFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.footerButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.footerButton:hover {
  background-color: #0056b3;
}

.alertEmptyContainer {
  padding: 20px;
  text-align: center;
}

.alertEmptyContainer2 {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  color: #555;
}
.todo-links {
  display: flex;
  justify-content: flex-start;
  gap: 20px; /* Adjust the gap between the links */
  margin-top: 10px;
}

.view-all-todo-link, .create-new-todo-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.view-all-todo-link:hover, .create-new-todo-link:hover {
  text-decoration: underline;
  color: #0056b3;
}