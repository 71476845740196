.itemChecklistEventName {
  width: 100%;
  background: #9f9fa9;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  text-align: center;
}

.itemChecklistItemName {
  background: #d4d4d7;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.itemChecklistItemDetails {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 4px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #16b7dc;
  color: white;
}

input[type="radio"]:checked + .passBtn22 {
  color: green;
  fill: green;
}

input[type="radio"]:checked + .inputDiv {
  display: block !important;
}

.PassBtn {
  color: inherit;
  size: 300px;
}
input[type="radio"]:checked + .failBtn22 {
  color: red;
  fill: red;
}

.itemChecklistGetItems {
  position: fixed;
  bottom: 90px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.itemChecklistGetItemName {
  border: none;
  color: #171616;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 10px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
  padding-left: 20px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  background: white;
}

.primary2 {
  justify-content: center;
  display: flex;
  max-height: 70vh;
  margin-bottom: 80px;
}

.primaryContainer2 {
  overflow: scroll;
  position: relative;
  width: 97%;
}

.itemChecklistContainer {
  justify-content: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
}

.itemChecklistEventName2 {
  position: relative;
  width: 97%;
  text-align: center;
  background: #2ca9e6;
  font-size: 20px;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.itemChecklistContainer33 {
  padding-bottom: 113px;
}

.assestPageHeader {
  margin-top: 45px;
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  float: left;
}
