.filterdropbtn {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #afafaf;
  appearance: none;
  background-color: #ececec;
  font-size: 100%;
  color: black;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}
.filterHolder {
  display: flex;
  align-items: center;
  width: 240px;
  padding-left: 10px;
}
.filterdropdown {
  width: 100%;
  position: relative;
  margin: auto;
  margin-bottom: 10px;
}

.dropdown-contentReports {
  display: none;
  background-color: #f1f1f1;
  overflow: auto;
  padding: 5px;
  border-left: 1px #a8a8a8 solid;
  border-right: 1px #a8a8a8 solid;
  border-bottom: 1px #a8a8a8 solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dropdown-contentReports a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
.filterArrow {
  position: absolute;
  right: 5px;
  font-size: 25px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 25px;
}
.ReportResultsContainer {
  width: 95%;
  position: relative;
  margin: 0 auto;
}

.ReportResultsCommandsHolder {
  display: flex;
  width: 95%;
  position: relative;
  margin: auto;
  height: 55px;
}

.ReportResultsCommands {
  display: flex;
  position: absolute;
  right: 0px;
}

.ReportResultsCommandsGenerate {
  background: #c0bcbc;
  padding: 10px;
  border-radius: 10px;
  border: 1px black solid;

  margin-right: 10px;
  font-size: 15px;
}
.ReportResultsCommandsReset {
  background: #c0bcbc;
  padding: 10px;
  border-radius: 10px;
  border: 1px black solid;

  font-size: 15px;
}

.ReportResultsCommandsPDF {
  float: left;
}

.ReportResultsCommandsBtnPDF {
  background: #c0bcbc;
  border-radius: 10px;
  border: 1px black solid;
  padding: 10px;
  font-size: 15px;
}

.ReportRecordsHolder {
  display: flex;
  width: 95%;
  position: relative;
  margin: auto;
  height: 10px;
  margin-top: 30px;
}
.ReportRecords {
  padding-bottom: 10px;
  margin-left: 10px;
  margin-top: 20px;
}

#reportsTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#reportsTable td,
#reportsTable th {
  border: 1px solid #ddd;
  padding: 8px;
  word-wrap: break-word;
  max-width: 200px;
}

#reportsTable tr:nth-child(even) {
  background-color: #fff;
  color: black;
}

#reportsTable tr:nth-child(odd) {
  background-color: #e6e6e694;
  color: black;
}

#reportsTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #c9c9c9;
  color: black;
}

.reportsPopupContainer {
  overflow: auto;
}

.emptySpaceReport {
  padding-bottom: 140px;
}

.filter-search-toggle-container-bottom {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  opacity: 1;
  visibility: visible;
  margin-bottom: 15px;
}

.filter-search-container2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  background-color: #fff;
  transition: max-height 0.3s ease, padding 0.3s ease, opacity 0.3s ease,
    visibility 0.3s ease;
  /*  max-height: 200px; /* Adjust based on content height */
  opacity: 1;
  visibility: visible;
  margin-bottom: 15px;
}

.filter-search-container2.collapsed {
  max-height: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  border: none;
}

.toggle-button2 {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
}

.toggle-button2:hover {
  background-color: #0056b3;
}

.clear-filters2 {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 20px;
}

.clear-filters2:hover {
  background-color: #0056b3;
}

@media screen and (min-width: 1000px) {
  .filter-search-toggle-container-bottom {
    margin-right: 250px;
  }
}
