.settingsHolder {
  display: flex;
  padding: 20px;
}

.settingsLeftContainer {
  background: #6f6f73;
  width: 200px;
  float: left;
  text-align: left;
  border-radius: 10px;
}

.settingsRightContainer {
  width: 100%;
  float: left;
  margin-left: 10px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid black;
}
.nav-links-header {
  color: #eaeaea;
  padding-top: 12px;
  display: flex;
  text-decoration: none;
}

.settingsRightLink {
  margin-bottom: 30px;
  margin-top: 10px;
  color: #b6aa67;
  font-size: 13px;
}
.settingsRightHeader {
  padding-bottom: 10px;
  border-bottom: 1px solid white;
  margin-bottom: 20px;    border-bottom: 1px solid #0000002e;
}
.settingsRightEditHeader {
  float: right;
}
