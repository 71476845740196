.taskHeader {
  border-bottom: 1px solid lightgray;
  font-size: 15;
  color: #919191;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 30px;
}

.taskNameDiv {
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
}
.taskInputContainer {
  text-align: center;
  position: relative;
  padding-bottom: 8px;
}

.itemTaskHeaderContainer {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
  padding-left: 15px;
  background: lightblue;
}

.editCheckListBtn {
  display: inline-block;
  position: absolute;
  right: 50px;
  top: 3px;
  margin-right: 10px;
  font-size: 32px;
  z-index: 5;
}

.itemIdentificationInProgressContainer {
  float: left;
}

.serialNumberInProgressContainer {
  display: inline-block;
  position: absolute;
  right: 10px;
  margin-right: 10px;
}

.failBtn {
  visibility: hidden;
  display: none;
}
.passBtn {
  visibility: hidden;
  display: none;
}

.space {
  padding: 0% 1%;
}
.passBtn2 {
  padding: 10px 50px 10px 50px;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.failBtn2 {
  padding: 10px 50px 10px 50px;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

input[type="radio"]:checked + .passBtn2 {
  background: white;
  border: 2px solid green;
  color: green;
  fill: green;
  border-radius: 10px;
}

input[type="radio"]:checked + .inputDiv {
  display: block !important;
}

.PassBtn {
  color: inherit;
  size: 300px;
}
input[type="radio"]:checked + .failBtn2 {
  background: white;
  border: 2px solid red;
  color: red;
  fill: red;
  border-radius: 10px;
}
.failBtn2 {
  color: inherit;
}

.MonitorBtn2 {
  padding: 10px 50px 10px 50px;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

input[type="radio"]:checked + .MonitorBtn2 {
  background: white;
  border: 2px solid #ff9600;
  color: #ff9600;
  fill: #ff9600;
  border-radius: 10px;
}
.MonitorBtn2 {
  color: inherit;
}

.inputDiv {
  display: none;
}

.inputDivActive {
  display: block;
  margin-top: 20px;
}

.qrscannerContainerTask {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  padding-bottom: 30px;
  width: 90%;
  margin: 0px auto;
}
.taskGroupAmount {
  float: right;
  padding-right: 10px;
}

.taskEditDiv {
  padding-right: 10px;

  display: flex;
}
.bagTaskNameInProgress {
  min-width: 200px;
  margin-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
}

.taskNameInProgressContainer {
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.taskNameInProgressdescription {
  display: flex;

  max-width: 60%;
}
.taskItemInProgressdescription {
  width: 100%;
  font-size: 13px;
  margin: 10px 0px;
  margin-left: 10px;
  color: #020202;
  display: flex;
}
.popup-overlay.full-overlay {
  padding-top: 0px !important;
}

.taskNameInProgressContainerName {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  font-size: 25px;
  color: #aea8a8;
  margin-top: 20px;
  justify-content: center;
}
.taskNameInProgressdetails {
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 10px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.taskItemInProgressdescriptionNumber {
  float: left;
  border: 1px solid;
  padding: 3px 7px;
  border-radius: 6px;
}
.taskItemInProgressdescription2 {
  padding-top: 3px;
  padding-left: 5px;
}
.taskNameInProgressEventContainer {
  padding-left: 15px;
}
.taskNameInProgressEventContainer2 {
  padding-left: 25px;
  padding-bottom: 15px;
}
.removeItemIcon {
  float: right;
  padding-right: 10px;
}
.compartmentImg23 {
  height: 100px;
  border-radius: 10px;
  float: left;
  margin-right: 10px;
  width: 100%;
}
.itemPicture3 {
  width: 70px;
  font-size: 15px;
}

.emptyHolderPageDiv {
  text-align: center;
  margin-top: 30px;
}

.selectItemLocation {
  height: 30px;
  padding-bottom: 10px;
}
.bagtaskNameInProgressContainer {
  margin-bottom: 15px;
  float: left;
  color: #0c9df4;
  display: flex;
  width: 100%;
  margin-left: 25px;
}
.taskEditInProgressContainer {
  float: right;
}
.PassFailTaskContainer {
  width: 100%;
  min-width: 120px;
}

.taskContainerHolder2 {
}

.submitItemBtn222 {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 17px;
  position: absolute;
  right: 15px;
  color: #5ba4f3;
  margin-top: 9px;
  top: 0px;
}

.form-controlSelect2 {
  font-size: 15px;
  margin-right: 10px;
  border: 1px solid black;
  background: white;
  border-radius: 5px;
  margin-left: 10px;
  width: 100%;
}

.bagListItemHolder {
  display: flex;
}

.bagListDelete {
  margin-bottom: 10px;
  background: #ff00003b;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid red;
}
.bagListItem {
  padding: 5px;
  margin-top: 2px;
}
.taskNameInProgressContainer2 {
  margin-bottom: 15px;
  float: left;
  display: flex;
  width: 100%;
  margin-left: 20px;
}

.bagListAdd {
  margin-bottom: 10px;
  background: #22e81c9c;
  padding: 5px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.selectItemDiv {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.smallActive {
  z-index: 3;
  position: sticky;
  top: 0;
  align-items: center;
  display: flex;
  font-size: 20px;
  border-bottom: 1.5px rgb(109, 109, 113) solid;
  width: 100%;
  justify-content: center;
}

.taskContainerInProgress {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  min-height: 70px;
}
.taskNoteInProgress {
  min-width: 200px;
  margin-right: 40px;
  padding-bottom: 10px;
  width: 100%;
}
.taskInProgressContainer {
  margin-left: 20px;
  margin-right: 20px;
}

.apparatusChecklistpageHeader {
  font-size: 1.5em;
  padding-left: 10px;
  width: 100%;

  padding-bottom: 20px;
}

.noteInProgressContainer {
  padding-top: 5px;
}

.noteInProgressInput {
  width: 100%;
  border: 1px solid #797777;
  border-radius: 2px;
  font-size: 100%;
  display: block;
  background: #fafafa;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.inProgressContainer {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
}

.inProgressName {
  width: 100%;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.checklistGoBackDiv {
  display: inline-block;
  position: absolute;
  top: 10px;
  margin-right: 10px;
  font-size: 20px;
  z-index: 5;
  margin-left: 10px;
  cursor: pointer;
}

.checklistSaveDiv {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-right: 10px;
  font-size: 20px;
  z-index: 5;
}

.checklistAddItemDiv {
  display: inline-block;
  position: absolute;
  right: 100px;
  top: 5px;
  font-size: 20px;
  z-index: 5;
  padding: 5px;
}

.submitChecklist {
  cursor: pointer;

  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  margin-bottom: 10px;
  border: none;
  background: none;
}
.submitBtnContainer {
  margin-top: 30px;
  margin-left: 40%;
  margin-right: 35%;
  text-align: center;
  font-size: 15px;
  margin-bottom: 150px;
}

.itemtaskContainerInProgress2 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  height: 70px;
  background: #b2b2bd;
  padding-left: 20px;
  padding-right: 20px;
}

.apparatusChecklistPageHeader {
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  float: left;
}
.apparatusChecklistpageHeaderEmpty {
  margin-top: 45px;
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  padding-bottom: 60px;
}

.inProgressChecklistNameHolder {
  display: flex;
  margin-left: 15px;
  margin-right: 20px;
}

.inProgressChecklistDate {
  position: absolute;
  right: 10px;
  margin-right: 20px;
}

.taskContainerHolder {
  padding-top: 70px;
}

.alert2 {
  background: rgb(241, 215, 217);
  padding: 10px;
  border-radius: 10px;
  color: rgb(105, 64, 63);
  border: 0.01px solid;
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.pageHeaderSubmitedCheck {
  margin-top: 45px;
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.checklistHistoryTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.checklistHistoryTable td,
th {
  padding: 10px;
  border: 1px solid #ddd;
}

.tableRow {
  background: #2ca9e6;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 39px;
  z-index: 10;
  color: white;
}
.checklistHistoryTable thead tr:nth-child(2) {
  background: #ccccd4;
  position: sticky;
  top: 70px;
}
.checklistHistoryTable tbody th:nth-child(even) {
  background-color: #f2f2f2;
}

.checklistHistoryTable tbody th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background-color: #fff;
  border-right: 1px solid #ccc;
  border: 1px solid black;
  color: #000;
}

.checklistHistoryTable tbody th:first-child {
  left: 0;
  z-index: 1;
}

.checklistHistoryTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.checklistHistoryTable th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
}

.tableDivSearch {
  padding-top: 10px;
}

.itemRowCheck {
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  width: 100%;
}

.itemRowCheckDone {
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 35px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  background: #3bfe6c4a;
}

.bagRowCheck {
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}

.bagRowCheckDone {
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 10px;
  margin-right: 10px;
  background: #3bfe6c4a;
}
.popup-overlay.scanner-overlay {
  z-index: 1001 !important;
}
.scanner-content {
  overflow-y: visible;
}

.confirmAddItemBtn {
  background: #63e858;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 50%;
  border-radius: 5px;
  padding: 3px 0px;
  color: white;
  margin-bottom: 5px;
}
@media screen and (max-width: 600px) {
  .checklistHistoryTable {
    font-size: 13px;
  }
  .checklistHistoryTable td,
  th {
    padding: 5px;
    border: 1px solid #ddd;
  }
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  padding-bottom: 5px;
  background: white;
  padding-right: 380px;
}

.pageButton {
  background: #2ca9e6;
  padding: 5px;
  color: white;
  border-radius: 5px;
  font-size: 20px;
}

.pageFirst {
  margin-left: 10px;
}

.pagePervious {
  margin-left: 10px;
}

.pageNext {
  margin-right: 10px;
}

.pageLast {
  margin-left: 10px;
}

.pageNumber {
  margin-left: 4%;
  font-size: 14px;
  width: 140px;
  padding-top: 10px;
}

.currentPage {
  margin-left: 4%;
  margin-right: 4%;
  padding-top: 5px;
}

.form-controlInput {
  width: 10px !important;
  margin-right: 5%;

  margin-right: 5%;
  border: 1px solid;
  border-radius: 4px;
  padding: 2px;
  font-size: 15px;
}

.form-controlSelect {
  height: 30px !important;
  width: 70px !important;
  margin-top: 3px;
  font-size: 10px;
  margin-right: 5%;
  border: 1px solid black;
  background: white;
  border-radius: 5px;
}

/* Style the arrow inside the select element: */
.form-controlSelect:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
}

/* Point the arrow upwards when the select box is open (active): */
.form-controlSelect.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.form-controlSelect div,
.form-controlSelect {
  color: #202020;
  cursor: pointer;
}

.checklistImgInput {
  padding-left: 10px;
  padding-bottom: 10px;
  width: 100%;
  padding-top: 10px;
}

.checklistImgInput2 {
  padding-top: 5px;
}

.checklistImg {
  width: 50px;
  height: 50px;
}

.submitItemBtnTask {
  background: none;
  border: none;
  position: fixed;
  right: 14px;
  color: black;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  margin-right: 10px;
  font-size: 18px;
  z-index: 5;
}
.addItemTask {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 60px;
  color: rgba(56, 129, 141);
  font-weight: normal;
  z-index: 11;
  padding: 5px 0px 0px 0px;
  transition: all 0.5s ease;
}
.addScan3C {
  display: inline-block;
  font-size: 18px;
  margin-right: 10px;
  position: absolute;
  right: 70px;
  top: 8px;
  z-index: 10;
}
.closeC {
  background: none;
  border: none;
  position: fixed;
  color: #938c8c;
  cursor: pointer;
  display: inline-block;
  top: 5px;
  margin-right: 10px;
  font-size: 18px;
  z-index: 5;
  padding-left: 10px;
}
.holderTestC {
  background: #fff;
  border-radius: 3px;
  padding-bottom: 10px;

  width: 100%;
  z-index: 10;
  margin-right: 10px;
  top: 0;
  margin-top: 30px;
}

.bagtaskholder {
  margin-left: 10px;
  margin-right: 10px;
}

.addPack {
  background: #ff780047;
  border-style: dashed;
  width: 90%;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: 10px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.noPicDivC {
  text-align: center;
  font-size: 30px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  padding: 5px;
  border-radius: 10px;
}

.show22 {
  display: block;
  margin-top: 10px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.imageChecklistContainer {
  display: flex;
}

.checklistDelete {
  background: #ffa7a7ad;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 5px;
  color: #e24a4a;
  margin-bottom: 5px;
  margin-top: 150px;
}

.upcomingEventContainer21 {
  border-bottom: 1px solid black;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  display: flex;
}
.upcomingEventDate21 {
  float: left;
  padding-right: 20px;
  white-space: nowrap;
}

.upcomingEventName21 {
  float: right;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.taskContainerHolder {
  padding-top: 40px;
  width: 100%;
  overflow: auto;
}

.taskGroupName {
  margin: auto;
  padding-left: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;

  padding-top: 10px;
  height: 50px;
}

.taskGroupNameImg {
  margin: auto;
  padding-left: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  height: 100px;
}

.taskGroupNameDone {
  margin: auto;
  margin-bottom: auto;
  padding-left: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  padding-top: 10px;

  background: #3bfe6c4a;
  height: 50px;
}
.taskGroupNameDoneImg {
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  background: #3bfe6c4a;
  height: 100px;
  background: #3bfe6c4a;
}

.taskBagCurrentLocation {
  padding-top: 10px;
}
.SubmitedChecklist {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 60px;
}

.taskGroupName3 {
  max-width: 60%;
  float: left;
}

.taskGroupAmount {
  right: 20px;
  padding-right: 10px;
  display: inline-block;
  position: absolute;
  color: #8f8f8f;
}

.SubmitedChecklistMember {
  margin-bottom: 10px;
}

.taskGroupNameMembers {
  margin: auto;
  padding-left: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  padding-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.topbar222 {
  height: 30px;
  display: flex;
  overflow: hidden;
  width: 60px;
  z-index: 3;
  background: #e83e3e00;
  top: 4px;
  right: 0px;
  position: absolute;
}
.bottomHeader {
  padding: 16px 0;
  box-sizing: border-box;
  color: white;
  min-height: 64px;
  font-size: 16px;
  text-align: center;
  background: blue;
  bottom: 88px;
  position: fixed;
  z-index: 15;
  width: 100%;
}

.bottomSwipeContainer {
  z-index: 1000;
  position: absolute;
}

.itemRowCompartment {
  margin: auto;
  margin-bottom: 35px;
  border-radius: 5px;
  padding-bottom: 35px;
  font-size: 20px;
  border-bottom: 1px solid #a3a3a3;
  box-shadow: 0 0px 20px -10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  margin-top: 20px;
}

.taskMainHolder {
  border-bottom: 3px solid lightgray;
  background: #f9f9f9;
  padding-bottom: 100px;
}

.taskHolder {
  width: 100%;
  align-items: center;
  border-top: 3px solid lightgray;
  min-height: 70px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 10px;
}
.taskHolder2 {
  width: 100%;
  min-height: 70px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
}
.bagTaskNameInProgress2 {
  min-width: 200px;
  margin-right: 40px;
  padding-top: 10px;
  width: 100%;
  display: flex;
}

.textInputLeft {
  float: left;
  width: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.textInputRight {
  margin-right: 10px;
}

.inventoryImgDivCheck {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.inventoryImgCheck {
  height: 100px;
  width: 100px;
  border-radius: 3px;
}

/*
@media (prefers-color-scheme: dark) {
  .checklistHistoryTable tr:nth-child(even) {
    background-color: #7b7b7b;
  }

  .inProgressName {
    background: #626264;
    box-shadow: none;
  }

  .input[type="radio"]:checked + .passBtn2 {
    background: #989898;
  }
  .input[type="radio"]:checked + .failBtn2 {
    background: #989898;
  }
  .submitChecklist {
    background: #646464;
  }
  .submitItemBtnTask {
    color: #d8d8d8 !important;
  }
}*/
