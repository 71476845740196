/* Site Overview Section */
.site-info-section {
    background-color: #f4f6f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.site-info-section h2 {
    font-size: 1.8em;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    padding-left: 10px;
    border-left: 4px solid #3b7c87;
}

/* Personal Information Section */
.personal-info-section {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 20px;
}

.personal-info-section h2 {
    font-size: 1.8em;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    padding-left: 10px;
    border-left: 4px solid #3b7c87;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .site-info-section, .personal-info-section {
        padding: 15px;
        margin-bottom: 15px;
    }

    .site-info-section h2, .personal-info-section h2 {
        font-size: 1.5em;
        padding-left: 8px;
    }
}
  .stats-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }


.pro-trial {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
}

.trial-banner {
    color: #333;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.main-content {
    display: flex;
    flex-direction: column;
}

.stats-section, .personal-stats {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.stat-box {
    background-color: #ffffff;
    padding: 20px;
    flex: 1;
    min-width: 150px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.stat-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.stat-box .icon {
    font-size: 2em;
    color: #3b7c87;
}

.stat-title {
    font-size: 1.2em;
    margin: 10px 0;
}

.stat-value {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.personal-stats {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    border-top: 1px solid #ddd;
}

.additional-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.alerts-box,
.todo-box {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.alerts-box h2,
.todo-box h2 {
    margin-top: 0;
}

.alerts-box ul,
.todo-box ul {
    list-style-type: none;
    padding-left: 0;
}

.alerts-box li,
.todo-box li {
    display: flex;
    align-items: left;
    margin: 10px 0;
}

.alerts-box .alert-icon,
.todo-box .todo-icon {
    margin-right: 10px;
    color: #004c6d;
}

.notifications-panel,
.activity-feed {
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    margin-top: 20px;
}

.notifications-panel h2,
.activity-feed h2 {
    margin-top: 0;
}

.notifications-panel ul,
.activity-feed ul {
    list-style-type: none;
    padding-left: 0;
}

.notifications-panel li,
.activity-feed li {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.notifications-panel .notification-icon,
.activity-feed .activity-icon,
.activity-feed .support-icon {
    margin-right: 10px;
    color: #004c6d;
}

.activity-feed a,
.alerts-box a,
.todo-box a {
    color: #004c6d;
    text-decoration: none;
}

.activity-feed a:hover,
.alerts-box a:hover,
.todo-box a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1000px) {
    .trial-banner {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .upgrade-button {
        margin-top: 10px;
    }

    .stats-section {
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }

    .stat-box {
        margin-bottom: 10px;
        padding: 10px;
    }

    .stat-box .icon {
        font-size: 1.5em;
    }

    .stat-title {
        font-size: 1em;
        margin: 5px 0;
    }

    .stat-value {
        font-size: 1.2em;
        margin-bottom: 5px;
    }

    .additional-sections {
        flex-direction: column;
    }

    .alerts-box,
    .todo-box {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .notifications-panel,
    .activity-feed {
        padding: 10px;
    }
}
