.header {
  padding-top: 3px;
  /* Center horizontally*/
  margin: 0 auto;
  display: none;
}
.small {
  z-index: 3;
  position: sticky;
  top: 0;
  align-items: center;
  display: flex;
  font-size: 20px;
  border-bottom: 1.5px rgb(109, 109, 113) solid;
  width: 100%;
  justify-content: center;
}

.smallHeader {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 100px;
  margin-left: 100px;
}
