.siteListHolder {
  padding-bottom: 30px;
}

.siteListContainer {
  margin-top: 10px;
  padding: 10px;
  padding-bottom: 10px;
  border-radius: 15px;
  border: 1.5px solid #b2b2b2;
  padding-bottom: 40px;
}
