.apparatusContainer {
  font-size: 0.9em;
  width: 100%;
  border-radius: 15px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.apparatusHolder {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 40px;
}
.apparatusNameContainer {
  width: 100%;
}
.apparatusImg {
  width: 100px;
  height: 100px;
  border-radius: 14px;
  float: left;
}

.alertNew {
  background: rgb(241, 215, 217);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  color: rgb(105, 64, 63);
  border: 0.01px solid;
  margin-top: 30px;
}

.EmptySpace {
  padding-bottom: 100px;
}

.apparatusName {
  font-size: 20px;
  margin-bottom: 10px;
  float: left;
  padding-top: 35px;
  padding-left: 10px;
}
.apparatusINStatus {
  float: right;
  background: #2dff2d;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 10px;
}
.apparatusOUTStatus {
  float: right;
  background: #f74426;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 10px;
}

.apparatusNameNoImage {
  font-size: 20px;
  margin-bottom: 10px;
  float: left;
  padding-top: 25px;
  padding-left: 10px;
  padding-bottom: 20px;
}

.apparatusDropdown {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 20px;
  display: none;
}
.apparatusDropdownNoImage {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 20px;
  display: none;
}
.apparatusDropdown2 {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.apparatusInventoryLink {
  border: none;
  color: #171616;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 10px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.apparatusChecklistLink {
  border: none;
  color: #171616;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 10px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
  padding-left: 20px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.apparatusChecklistLinkEdit {
  border: none;
  color: #171616;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 10px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

@media (min-width: 500px) {
  .apparatusChecklistLinkEdit {
    position: absolute;
    right: 10px;
  }
}

.apparatusPageHeader {
  margin-top: 45px;
  font-size: 2em;
  padding-left: 10px;
  width: 100%;
  float: left;
  padding-bottom: 20px;
}

.apparatusBox {
  position: relative;
  top: 45px;
  margin: 0 auto;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  max-height: 70vh;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.apparatusListContainer {
  padding-bottom: 100px;
}

.photoDiv {
  margin-top: 15px;
  margin-bottom: 30px;
}
.photoDivAdd {
  margin-top: 15px;
  margin-bottom: 10px;
}
.submitItemBtn3 {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 17px;
  position: absolute;
  right: 29px;
  color: rgba(56, 129, 141);
  margin-top: 9px;
}

.submitItemBtn2 {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 0px;
  color: rgba(56, 129, 141);
  font-weight: normal;
  z-index: 11;
  padding: 9px 10px 10px 10px;
  transition: all 0.5s ease;
}
.editApparatusBtn {
  background: #ffa7a7ad;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 90%;
  border-radius: 5px;
  padding: 3px 0px;
  color: #e24a4a;
  margin-bottom: 5px;
}

.editApparatusBtnContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  margin-top: 50px;
}

.confirmDeleteApparatusBtn {
  background: #ea5252;
  border: none;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  width: 40%;
  border-radius: 5px;
  padding: 10px 0px;
  color: white;
  margin-bottom: 5px;
  margin-top: 5px;
}

.confirmCancelApparatusBtn {
  background: #c4c4c4ad;
  border: none;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  width: 40%;
  border-radius: 5px;
  padding: 10px 0px;
  color: #000;
  margin-bottom: 5px;
  margin-top: 5px;

  margin-right: 10px;
}

.apparatusEmptyHolder {
  position: fixed;
  top: 50%;
  z-index: 0;
  position: sticky;
  font-size: 20px;
  width: 100%;
  margin-top: 20%;
}
.apparatusEmptyName {
  justify-content: center;
  display: flex;
  font-size: 20px;
  width: 100%;
}

.apparatusEmptyButton {
  justify-content: center;
  display: flex;
  font-size: 14px;
  color: #8f8f8f;
  padding-top: 10px;
}

.deleteInfoApparatusContainer {
  text-align: center;
  margin-bottom: 30px;
  color: gray;
  margin-top: 40px;
  padding-right: 50px;
  padding-left: 50px;
}

.submitItemBtn2:focus {
  outline: none;
  box-shadow: none;
}

.popup-overlay {
  background: linear-gradient(
    0deg,
    rgba(26, 26, 26, 0.5) 0%,
    rgba(26, 26, 26, 0.5) 89%,
    rgba(26, 26, 26, 0.2945553221288515) 95%,
    rgba(255, 255, 255, 0.8547794117647058) 100%
  );
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(210, 21, 21, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.keyboard {
  padding-bottom: 100px;
}

.nokeyboard {
  padding-bottom: 200px;
}

.ScrollOff-content {
  overflow: visible;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
/*
@media (prefers-color-scheme: dark) {
  .bottom-div-holder {
  background-color: #343434 !important;
  }
  .bottom-div-holder{
    border-top: 1px solid #585858 !important;
  }
  .apparatusInventoryLink {
    color: white;

    box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }
  .apparatusChecklistLink {
    color: white;

    box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }
  .apparatusChecklistLinkEdit {
    color: white;

    box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }

  .css-1s2u09g-control {
    background: #505050 !important;
    border: 1px solid transparent !important;
  }
  .css-1pahdxg-control {
    background: #505050 !important;
    border: 1px solid transparent !important;
  }
  .css-26l3qy-menu {
    background: #505050 !important;
    border: 1px solid transparent !important;
  }
  .css-qc6sy-singleValue {
    color: #fff !important;
  }
  .itemRow {
    border-bottom: 1px solid #b8afaf !important;
    background: #5c5c5c !important;
    box-shadow: none !important;
  }
  .holderTest {
    background: #343434 !important;
  }
  .bagRow {
    background: #5c5c5c !important;
    box-shadow: none !important;
  }
  .itemRow2 {
    background: #5c5c5c !important;
    box-shadow: none !important;
  }
  .apparatusINStatus {
    color: #000;
  }
  .addImageDiv {
    color: #ababb0;
  }
}
*/
