.pageHeader {
  margin-top: 45px;
  font-size: 2em;
  padding-left: 2s0px;
  width: 100%;
  float: left;
  /* margin-bottom: 30px;*/
}

.addItem {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 3px;
  margin-right: 10px;
  font-size: 32px;
  z-index: 5;
  color: #38818d;
}
.goBackDivarrow {
  display: flex;
  float: left;
  font-size: 22px;
  padding-top: 2px;
}
.goBackDivtxt {
  display: flex;
  float: right;
  font-size: 18px;
  padding-top: 3px;
}
.addItemPlus {
  display: flex;
  float: left;
  padding-top: 1px;
  font-size: 20px;
  padding-right: 5px;
}
.inspectionImg {
  margin-top: 20px;
  margin-bottom: 10px;
}
.addItemMobile {
  display: inline-block;
  position: fixed;
  right: 10px;
  top: 3px;
  margin-right: 10px;
  font-size: 32px;
  z-index: 5;
  color: #38818d;
}

.inspectionImgContainer {
  background: lightgrey;
  width: 60PX;
  BORDER-RADIUS: 10px;
  justify-content: center;
  display: flex;
  height: 60px;
  align-items: center;
  margin-bottom: 20px;
}

.taskDesc2 {
  font-size: 13px;
  color: #444444;
}

.InventoryTableImg{
  height: 50px;
  border-radius: 10px;
  float: left;
}
.taskName2 {
  font-weight: bold;
  margin-bottom: 5px;
}

.taskNameHolder {
  margin-top: 20px;
  margin-bottom: 10px;
}


.textInputLeft2 {
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  width: 99%;
}



#reportsTable {
  width: 100%;
  border-collapse: collapse;
}

#reportsTable th,
#reportsTable td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

#reportsTable th {
  position: sticky;
  top: 0;
  background: #f2f2f2;
  z-index: 1; /* Ensure the header is above the table rows */
}









@media screen and (max-width: 1500px) {
  .addItemDispaly {
    display: none !important;
  }
}

@media screen and (min-width: 1500px) {
  .addScan {
    display: none !important;
  }
  .addItemMobile {
    display: none !important;
  }
}

.addItemDispaly {
  display: inline-block;
  position: absolute;
  right: 10px;
  margin-right: 10px;
  font-size: 15px;
  border: 1.5px solid #38818d3d;
  padding: 8px;
  border-radius: 10px;
  color: #38818d;
}

.addScan {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 13px;
  font-size: 32px;
  z-index: 5;
  color: #38818d;
}
.addItemReact {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 32px;
  z-index: 5;
  color: #38818d;
}
.addItemReactText {
  font-size: 18px;
  margin-top: 5px;
}

.inventorySelectHolder {
  display: flex;
  position: relative;
  margin: auto;
}
.inventorySelectHolder2 {
  display: flex;
  position: relative;
  margin: auto;
}

.inventorySelect1 {
  width: 100%;
  margin-right: 10px;
}
.inventorySelect2 {
  width: 100%;
}
.RedText{
  color: red !important;
}

.popup-content {
  text-align: center;
  padding: 20px;
}

.popup-content h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.popup-content p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}


.emptySpace {
  padding-bottom: 10px;
}
.searchDiv {
  margin: auto;
  padding-top: 10px;
  width: 95%;
}
.topbarEmptySpace {
  padding-bottom: 60px;
}
.topbarEmptySpace2 {
  padding-bottom: 40px;
}
.searchBar {
  padding: 10px 10px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 20px;
  width: 100%;
  border-style: solid;
  background: lightgray;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

input:focus {
  outline: none;
  border: 2px solid rgba(56, 129, 141);
  font-weight: bold;
}

.itemHeader {
  border-bottom: 1px solid lightgray;
  font-size: 15;
  color: #919191;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 20px;
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid lightgray;
  padding-left: 20px;
  padding-right: 20px;
  max-height: 60px;
  height: 60px;
  padding-top: 4px;
}
.itemDiv {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.itemDivName {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 4px;
}
.itemDivType {
  font-size: 14px;
  color: #7c7c7c;
}

.item {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 75px;
}

.inventoryImg {
  height: 60px;
  width: 60px;
  border-radius: 3px;
}

.inventoryImgDiv {
  width: 75px;
}
.noPicDiv {
  text-align: center;
  font-size: 50px;
  width: 60px;
}
/* Popup style */
/* Popup style */

/* Popup style */
/* Popup style */
/* Popup style */
.popup-box {
  position: fixed;
  background: #0000008c;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 5;
}

.box {
  position: relative;
  margin: 0 auto;
  max-height: 70vh;
  margin-top: 50px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;

  margin-left: 10px;
  margin-right: 10px;

  overflow: auto;
}

.closePopup {
  font-size: 16px;
  cursor: pointer;
  z-index: 11;
  color: rgba(56, 129, 141);
  position: fixed;
  padding: 9px 10px 10px 10px;
}
.popupTitle {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  padding-top: 10px;
  margin: auto;
  width: 45%;
}
.popupTitle3 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 15px;
  padding-top: 10px;
  margin: auto;
  width: 60%;
}
.selectOptionDiv {
  padding-bottom: 10px;
}
.submitItemBtn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 14px;
  color: black;
  cursor: pointer;
}
.popupContainer {

}
.addItemInput {
  width: 100%;
  border: 1px solid #afafaf;
  border-radius: 8px;
  font-size: 100%;
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  background-color: white;
  text-indent: 5px;
}

.addItemInputSelect {
  width: 100%;
  border: 1px solid #afafaf;
  border-radius: 8px;
  font-size: 100%;
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  text-indent: 5px;
  background-color: white;
  color: black;
}

.NewInputLabel {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;width: 100%;
}
.NewInputLabel::after {
  content: attr(data-domain);
  position: absolute;
  top: 17px;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;

  color: rgba(0, 0, 0, 0.6);
  padding-left: 10px;
}
.NewInput {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding-top: 27px;
  padding-bottom: 10px;
  padding-left: 15px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #afafaf;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  font-size: 16px !important;
  color: black;
  resize: none;
  padding-right: 20px;
}

.NewInput:focus {
  outline: none;
  border: 2px solid rgba(56, 129, 141);
  font-weight: bold;
}

.NewInputImage {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding-top: 27px;
  padding-bottom: 5px;

  border-radius: 8px;
  border: 1px solid #afafaf;

  background-color: white;
  font-size: 100%;
  color: black;
}
.NewInputImage:focus {
  outline: none;
  border: 2px solid rgba(56, 129, 141);
  font-weight: bold;
}

.inventorySearch {
  width: 100%;
  border: #e9e9e9;
  border-radius: 8px;
  font-size: 16px;
  display: block;
  background: #e9e9e9;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  text-indent: 10px;
}

.InputSpace {
  padding-left: 10px;
}

input[type="text"] {
  /* max-width: calc(100% - 10px);*/
  font-size: 16px;
}


.custom-select {
  font-size: 16px;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
}

.addImageDiv {
  display: inline-block;
  font-size: 35px;
  padding-top: 15px;
}
.addImageHolder {
  display: inline-block;
  float: right;
  right: 10px;
  position: absolute;
  top: 6px;
  max-width: 10px;
}
.addImage {
  float: right;
  width: 40px;
  max-height: 54px;
  height: 100px;
}

.inventorySelect {
  width: 100%;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #e1e1e1;
  border: none;
  display: flex;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  text-align-last: center;
  -webkit-appearance: none; /* turn off drop-down arrow*/
  -moz-appearance: none;
}
.PerviousNextHolder {
  margin-top: 10px;
  background-color: #ffffff;
  border-top: 1px solid #cbcbcb;
}
.topbarEmptySpace2 {
  padding-bottom: 40px;
}
.BottomFixPosition2 {
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 150;
  /*position: sticky !important;*/
  bottom: 0px !important;
  right: 0px;
}

  /* Container to enable scrolling with a fixed header */

/* Table Styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table-header{
  padding: 12px;
  text-align: left;
}

 .custom-table-cell {
  padding: 12px;
  text-align: left;
}

.custom-table-header {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  border-bottom: 2px solid #ddd;

}

.custom-table-cell {
  border-bottom: 1px solid #ddd;
}

.custom-table-header span {
  font-weight: bold;
  font-size: 14px;
}

.custom-table-cell i {
  font-style: normal;
}



.pagination2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
 /* margin: 20px 0;*/
  font-family: Arial, sans-serif;
  flex-wrap: wrap;
}

.pagination2 .results-info {
  font-size: 14px;
  margin-left: 10px;
  flex-grow: 1;
  text-align: left;
}

.pagination-controls {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

.pagination-controls button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 6px 18px;
  margin: 0 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-controls button:not(:disabled):hover {
  background-color: #0056b3;
}

.pagination-controls span {
  font-size: 16px;
  font-weight: bold;
}


  .NewInputLabel123 {
    position: relative;
    display: block;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;      width: 100%;


}
.NewInputLabel123::after {
  content: attr(data-domain);
  position: absolute;
  top: 17px;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;

  color: rgba(0, 0, 0, 0.6);
  padding-left: 10px;
}



.toggle-button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
}

.toggle-button:hover {
  background-color: #0056b3;
}


.filter-search-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  transition: max-height 0.3s ease, padding 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
 /*  max-height: 200px; /* Adjust based on content height */
  opacity: 1;
  visibility: visible;
}

.filter-dropdown {
  width: 100%;
  position: relative;  /* Ensure the dropdown is positioned relative to the container */
}

.Select__control {
  z-index: 2;  /* Ensure the control part of the select has a higher z-index */
}

.Select__menu {
  z-index: 3;  /* Ensure the dropdown menu itself has a higher z-index */
}

.filter-search-container.collapsed {
  max-height: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  border: none;
}
.table-container.collapsed  {
  max-height: calc(100vh - 200px) !important
}

.filter-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.filter-dropdown {
  width: 100%;
}

.inventorySearch {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.inventorySearch:focus {
  border-color: #007bff;
}

.clear-filters {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clear-filters:hover {
  background-color: #0056b3;
}



.status-failed, .status-pass, .status-monitor, .status-expired {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 12px;
}

.status-failed {
  background-color: #ffe5e5;
  color: #d9534f;
}

.failed-dot {
  background-color: #d9534f;
}

.status-pass {
  background-color: #e5ffe5;
  color: #5cb85c;
}
.status-expired {
  background-color: #ffd6d6;
  color: #b41313;
}

.pass-dot {
  background-color: #5cb85c;
}

.status-monitor {
  background-color: #fffb9d;
  color: #8e9030;
}

.monitor-dot {
  background-color: #5bc0de;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

/* Responsive Design */
@media screen and (min-width: 1500px) {
  .filter-search-container {
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
  }

  .filter-label {
    display: none;
  }

  .filter-dropdown {
    flex: 1;
    min-width: 150px;
  }

  .inventorySearch {
    flex: 2;
    min-width: 150px;
  }

  .clear-filters {
    flex: 0 0 auto;
  }

  .toggle-button {
    display: none;
  }
 

  .middle-container{
    margin-right: 250px;
  }
}


.containerInventory {
 /*  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
 background-color: #f8f9fa;*/
}

.top {
  flex: 0 1 auto;
}

.filter-search-toggle-container {
  display: flex;
  flex-direction: column;
}

.filter-search-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  transition: max-height 0.3s ease;

}

.filter-search-container.collapsed {
  max-height: 0;
}

.middle-container {
  flex: 1 1 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.middle {
  display: flex;
  flex-direction: column;
  height: 100%;
      overflow-x: auto;
/* Ensure no scrolling on the middle div */
}

.table-container {
/*  overflow-y: auto; 
  overflow-x: auto;*/
}

.table-container {
  flex: 1 1 auto;

  max-height: 500px; /* Adjust height as needed */
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: calc(100vh - 250px);
}



.bottom {
  flex: 0 0 auto; /* Fixed height and non-flexible */
  background-color: #ffffff;
  position: fixed; /* Fix the bottom at the bottom of the screen */
  bottom: 0;
  width: 100%;
}

.min-max-width {
  min-width: 10px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.min-max-width2 {
  min-width: 10px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.min-max-widthLogo {
  min-width: 10px;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.search-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.inventorySearchButton {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  margin-right: 10px;
}

.search-button {
  background-color: #007BFF;
  border: none;
  border-radius:4px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button:hover {
  background-color: #0056b3;
}

.arrow-icon {
  color: white;
  font-size: 20px;
}



.imagePreviewContainer {
  display: flex;
  flex-wrap: wrap;
}

.imageHolder {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.addImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.removeImageButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.NewInputImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 80px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
}


.highlighted {
  border-left: 4px solid #8f8f8f;
  background-color: rgb(70 70 70 / 10%);
}



.popup-container2 {
  position: fixed  !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 10001;
  width: 90%; /* Use a percentage for width to make it responsive */
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}
/* Custom styles for react-select */
.custom-select {
  width: 100%;
}

.custom-select12 .select__menu {
  z-index: 10100 !important; /* Ensure the menu has a higher z-index */
  width: 90%;
}
























.scrollable-results {
  max-height: 200px; /* Adjust height as needed */
  overflow-y: auto;
}

.item-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer; /* Make the item clickable */
}

.item-container {
  display: flex;
  align-items: center;
}

.item-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-img {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}

.item-name {
  font-size: 14px; /* Adjust size as needed */
  margin-top: 5px;
}

.no-results-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.no-results-name {
  font-size: 16px; /* Adjust size as needed */
  margin-bottom: 10px;
}

.popup-bottom-holder {
  margin-top: 10px;
}

.new-input-label {
  display: block;
  margin-top: 15px;
}

.new-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.search-button,
.create-button {
  background-color: #39f;
  color: #fff;
  border: none;
  padding: 10px 11px;
  cursor: pointer;    margin-left: 10px;
}

.search-button:hover,
.create-button:hover {
  background-color: #08f;
}

.selected-item-container {
  display: flex;
  align-items: center;
}

.selected-item-img {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  margin-right: 10px;
}

.selected-item-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.selected-item-info h3 {
  margin: 0;
  font-size: 16px; /* Adjust size as needed */
}

.selected-item-info p {
  margin: 5px 0 0 0;
  font-size: 14px; /* Adjust size as needed */
}

.search-again-button {
  background-color: #39f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.search-again-button:hover {
  background-color: #08f;
}
.search-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}





.selected-option {
  display: inline-flex;
  align-items: center;
  background: #f0f0f0;
  border-radius: 15px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.remove-button {
  background: none;
  border: none;
  color: #333;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
}

















.overlay { 
  position: fixed; 
  overflow-y: scroll;
}


.scrollable {
  -webkit-overflow-scrolling: touch;
  overflow-y:auto;
}





.container22 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  
}

/* Top section */
.top2 {

}

/* Middle section */
.middle2 {
  flex-grow: 1; /* Allow this section to grow and fill available space */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.table-container2 {
  width: 100%;
  height: 100%;
  overflow: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  font-size: 16px;
}

th, td {
  border-bottom: 1px solid #ddd;
    padding: 14px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick the header to the top of the table */
  z-index: 1; /* Ensure the header stays above the content */
}

/* Bottom section */
.bottom2 {
  text-align: center;
  flex-shrink: 0; /* Prevent shrinking */
}
.bottom22 {
  text-align: center;
  flex-shrink: 0; /* Prevent shrinking */
}

.ChecklistSelectPopup{
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;

  z-index: 100;
  justify-content: center;
  display: flex;
}


.selected-options-display {
  white-space: nowrap;
  overflow-x: auto;
  float: left;
}












