.apparatusChecklist {
  font-size: 12px;
  color: #848787;
  padding-top: 10px;
  padding-left: 10px;
}

.checkContainer {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.checkListContainer {
  margin-top: 10px;
  padding: 10px;
  border-radius: 15px;
  border: 1.5px solid #b2b2b2;
}

.checklistPopup-box {
  position: fixed;
  background: #0000008c;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 7;
}
.taskItemEventHolder {
  width: 100%;
  float: left;
}
.taskItemEventList {
  margin-left: 40px;
}

.checklistEditBox {
  position: relative;
  margin: 0 auto;
  background: #fff;
  padding: 10px;
  overflow: auto;
  height: 100vh;
}

.addTaskGroupContainer {
  background: #16b7dc;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #6a6a6c;
  padding-left: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.ChecklistStepOrder{
  font-size: 12px;
  background: lightgray;
  padding: 5px;
  width: 50px;
  border-radius: 10px;
}

.checklistInputPopup-box {
  position: fixed;
  background: #0000008c;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 7;
}
.checklistEditInputBox {
  position: relative;
  margin: 0 auto;
  max-height: 70vh;
  margin-top: 50px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.ChecklistAddStep {
  background: #cbc7c7;
  display: inline-table;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.checklistEditItemright {
  display: inline-block;
  width: 30%;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: right;
}

.ChecklistStepContainer {
  display: flex;
}

.ChecklistStepOrderDelete {
  margin-top: 5px;
  right: 30px;
  display: FLEX;
  POSITION: ABSOLUTE;
}

.ChecklistStepOrderRemove {
  margin-top: 10px;
  right: 20px;
  display: FLEX;
  POSITION: ABSOLUTE;
  font-size: 12px;
}

.checkboxLabel {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 2px;
}

.NewInputCheckBox {
  width: 20px;
  height: 20px;
  border: 3px solid darkgray;
  border-radius: 6px;
  box-sizing: border-box;
}

.checklistEditPopupDiv {
  overflow: auto;
  max-height: 40vh;
}
.checklistPageheader {
  margin-top: 50px;
  margin-bottom: 20px;
}

.editChecklistName {
  font-size: 19px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.taskDiv {
  padding-bottom: 15px;

  float: left;
}

.editTaskDiv {
  float: right;
}
.addTaskDiv {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 3px solid black;
  margin-top: 10px;
}

.selectNameContainer {
  margin-bottom: 5px;
  padding-top: 10px;
  font-size: 14px;
  color: #777;
}

.editTaskGroupName {
  margin-left: 10px;
  margin-right: 10px;
}
.taskGroupContainer {
  margin-left: 15px;
  margin-right: 15px;
}

.taskLinkedCompartment {
  margin-left: 10px;
  display: inline;
}

.taskGroupHeader {
  background: #16b7dc;
  padding-top: 5px;
  margin-top: 10px;
  padding-bottom: 25px;
  border-radius: 6px;
  border: 1px solid #6a6a6c;
  padding-left: 5px;
  color: white;
}
.editTaskDiv {
  right: 15px;
  position: absolute;
}
.editTaskDiv2 {
  right: 30px;
  position: absolute;
  padding-top: 5px;
}
.taskContainer {
  width: 100%;
  display: inline-block;
  background: #d9e0e3;
  border: 1px solid #6a6a6c;
  padding-bottom: 5px;
}

.task {
  float: left;
  padding-left: 10px;
  padding-top: 5px;
}

.checklistPageTaskDescription {
  font-size: 13px;
  padding-left: 15px;
}

.checklistPageNoteRequired {
  position: absolute;
  right: 20px;
  padding-top: 5px;
}

.checklistEditItemHeader {
  border-bottom: 1px solid lightgray;
  font-size: 15px;
  color: #919191;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 25px;
}

.checklistEditItemContainer {
  display: flex;
  border-bottom: 1px solid lightgray;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.checklistEditItem {
  display: inline-block;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
}

.addTaskGropDiv {
  padding-bottom: 130px;
  margin-top: 20px;
}

.editChecklistPageNameContainer {
  margin-top: 50px;
  padding-bottom: 20px;
}

.addTaskTypeBtn {
  font-size: 17px;
  background: #efefef;
  cursor: pointer;
  padding-top: 5px;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.addTaskTypeBtn2 {
  padding-left: 10px;
}

.editChecklistSelectHolder {
  padding-top: 10px;
}
.editChecklistSelectInputHolder {
  padding-top: 10px;
}

.selectOptionDivChecklist {
  padding-bottom: 10px;
  padding-top: 10px;
}
.editChecklistDeleteContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  margin-top: 20px;
}

.editBag {
  justify-content: center;
  display: flex;
  margin-bottom: 14px;
  font-size: 20px;
  margin-top: 20px;
}

.checklistListHolder {
  padding-bottom: 300px;
}

.checklistStatus {
  float: right;
  background: #e83636;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  font-size: 10px;
  margin-right: 10px;
}

.checklistStatusActive {
  float: right;
  background: #86ff82;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  font-size: 10px;
  margin-right: 10px;
}

.checklistStatusDisabled {
  float: right;
  background: #ff9696;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  font-size: 10px;
  margin-right: 10px;
}

.detailsPopupContainer222 {
  padding-bottom: 20px;
  max-height: 400px;
  width: 300px;
  z-index: 2;
  position: relative;
  padding-top: 10px;
}

.container2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container2:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container2 .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.alertHolder {
  display: flex;
}

.alertMemberHeader2 {
  padding-bottom: 20px;
}

.alertMemberHeader {
  padding-bottom: 10px;
}

.editAlertContainer {
  display: flex;
  margin-bottom: 80px;
  margin-top: 20px;
}
